import React, { Component } from 'react';
import Aside from '../components/aside/aside';
import PayData from '../components/payment/pay-data';
import Header from "../components/header/header";

class Payment extends Component {
    render() {
        return (
            <div className="workspace">
                <div className="column">
                    <Header/>
                    <div>
                        <Aside />
                        <div className="payment justify-center">
                            <div className="container">
                                <div className="payment-sections column">
                                    <div className="payment-left-right row-responsive justify-center">
                                        <div className="payment-left column">
                                            <div className="reservation-title justify-center">
                                                <h3>DATOS DE CONTACTO</h3>
                                            </div>
                                            <div className="white-space-24"></div>
                                            <div className="all-traveler-data column">
                                                <div className="traveler-data column">
                                                    <p>Nombre</p>
                                                    <div className="white-space-8"></div>
                                                    <input className="input" type="text" name="name" id="name" required />
                                                </div>
                                                <div className="white-space-8"></div>
                                                <div className="traveler-data column">
                                                    <p>Apellidos</p>
                                                    <div className="white-space-8"></div>
                                                    <input className="input" type="text" name="last_name" id="last_name" required />
                                                </div>
                                                <div className="white-space-8"></div>
                                                <div className="traveler-data column">
                                                    <p>Correo</p>
                                                    <div className="white-space-8"></div>
                                                    <input className="input" type="email" name="email" id="email" required />
                                                </div>
                                                <div className="white-space-8"></div>
                                                <div className="traveler-data column">
                                                    <p>Teléfono</p>
                                                    <div className="white-space-8"></div>
                                                    <input className="input" type="text" name="telephone" id="telephone" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-right column">
                                            <div className="payment-title justify-center">
                                                <h3>DATOS DE PAGO</h3>
                                            </div>
                                            <div className="white-space-24"></div>
                                            <div className="pay column">
                                                <PayData />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-24"></div>
                                    <div className="button-pay column">
                                        <div className="btn-container justify-end">
                                            <button className="btn-pay">PAGAR</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Payment;