import React, { Component } from 'react';
import moment from "moment-timezone";

// Web Components
import Aside from '../components/aside/aside';
import DashBoardCards from '../components/dashboard/cards';
import SummaryOrders from '../components/dashboard/summary-orders';
import Header from "../components/header/header";
import Request from '../core/httpClient';
const req = new Request();

class Tablero extends Component {
    constructor(props) {
        super(props);
        this.state ={
            products: 0,
            reservationsT: 0,
            reservations: []
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const res = await req.post('/admin/products/getTotalProducts');
        if (res.products) {
            this.setState({ products: res.products });
        }

        const result = await req.post('/admin/reservations/getTotalReservations');
        if (result.reservations) {
            this.setState({ reservationsT: result.reservations });
        }

        const lastReservations = await req.post('/admin/reservations/lastReservations');
        if (lastReservations.reservations) {
            console.log(lastReservations.reservations);
            this.setState({ reservations: lastReservations.reservations });
        }
    }

    render() {
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-16"></div>
                                <DashBoardCards products={this.state.products} reservations={this.state.reservationsT} />
                                <SummaryOrders reservations={this.state.reservations} />
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Tablero;
