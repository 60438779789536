import React, { Component } from "react";
import Request from '../../core/httpClient';
import { withRouter } from 'react-router-dom';

const req = new Request();

class DetailCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        es: { categoria: "" },
        en: { categoria: "" },
        pt: { categoria: ""}
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({loading: true, message: ''});
    const match = this.props.match;
    if(match.params.id) {
      const res = await req.post(`/admin/categories/get/${match.params.id}`);
      this.setState({message: res.message});
      if(res.category) {
        this.setState({category: res.category.categoria, idCategoria: res.category.id_categoria});
      }
    }
    this.setState({loading: false});
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({message: '', loading: true});
    const data = {
      idCategoria: this.state.idCategoria || 0,
      categoria: this.state.category
    };
    const res = await req.post('/admin/categories/save', data);
    if(res.category) {
      window.location = `/categorias/modificar/${res.category.id_categoria}`;
    }
    this.setState({message: res.message, loading: false});
  }

  inputChange(key, event) {
    const category = this.state.category;
    Object.assign(category[key], { categoria: event.target.value });
    this.setState({ category });
  }

  render() {
    return (
      <div className="add-category justify-center">
        <div className="container-add-product column align-center">
          <div className="row container">
            <div className="column justify-center">
              <form className="column gutters htmlForm-add-product" onSubmit={this.handleSubmit.bind(this)}>
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la Categoía(ESP)
                </label>
                <div className="white-space-8" />
                <input type="text" name="category_name" id="category_name" className="input input-text" required value={this.state.category.es.categoria || ""} onChange={this.inputChange.bind(this, 'es')} />
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la Categoía(ING)
                </label>
                <div className="white-space-8" />
                <input type="text" name="category_name_ing" id="category_name_ing" className="input input-text" required value={this.state.category.en.categoria || ""} onChange={this.inputChange.bind(this, 'en')} />
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la Categoía(PRT)
                </label>
                <div className="white-space-8" />
                <input type="text" name="category_name" id="category_name" className="input input-text" required value={this.state.category.pt.categoria || ""} onChange={this.inputChange.bind(this, 'pt')} />
                <div className="white-space-16" />
                <div className="row justify-center">
                  {this.state.message}
                </div>
                <div className="white-space-16" />
                <div className="btn-container-publish justify-center">
                  {this.state.loading?
                    <div className="row justify-center">
                      <i className="fas fa-spinner fa-spin" />
                    </div>
                    :
                    <button className="btn btn-aqua" type="submit">
                      <i className="fas fa-th-large" />
                      &nbsp;
                      {this.props.categoryStatus === "agregar"
                        ? "PUBLICAR CATEGORIA "
                        : "ACTUALIZAR CATEGORIA"}
                    </button>
                  }
                </div>                
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DetailCategory);
