import React, { Component } from "react";

//Web Components

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  buscar(event) {
    event.preventDefault();
    const busqueda = event.target.busqueda.value;
    const data = {
      busqueda
    };
    this.props.search(data);
  }

  render() {
    return (
      <div className="container-search-product">
        <form className="form-filter-products" onSubmit={this.buscar.bind(this)}>
          <div className="search-input">
            <input type="text" className="input input-text" placeholder="Título" name="busqueda" />
          </div>
          <div className="button-search">
            <button className="btn btn-yellow">
              <i className="fas fa-search" />&nbsp; BUSCAR
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Filters;
