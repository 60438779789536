import React, { Component } from "react";

// Web Component
import ProductsRow from "./product-row";

class ProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numitems: 10
    };
  }
  render() {
    let products = [];
    if (this.props.products) {
      products = this.props.products.slice((this.props.page - 1 ) * 10 , this.props.page * 10);
    }
    return (
      <div className="products-table">
        <div className="cart-container justify-center">
          <div className="column">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th> IMAGEN </th>
                    <th className="th-name"> TITULO </th>
                    <th> ACTIVO </th>
                    <th> RELEVANTE </th>
                    <th> PRECIO ADULTO</th>
                    <th> PRECIO MENOR</th>
                    <th> EDITAR </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fas fa-spinner fa-spin"></i>
                      </td>
                    </tr>
                  ) : null}
                  {products.map((product) => (
                    <ProductsRow key={product.id_producto} {...product} />
                  ))}
                  {this.props.products_error ? (
                    <tr>
                      <td colSpan="7">{this.props.products_error}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsTable;

// correo de envio reservas@vamoscancun.com

