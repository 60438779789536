/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider, addLocaleData } from "react-intl";
import { Consumer } from "../context";

// Web Components
import Header from "../components/header/header";

// Web Pages
import Login from "../pages/login";
import Tablero from "../pages/dashboard";
import Reservaciones from "../pages/reservaciones";
import Clientes from "../pages/clientes";
import Usuarios from "../pages/usuarios";
import Productos from "../pages/productos";
import Clasificaciones from '../pages/clasificaciones';
import Categorias from "../pages/categorias";
import Hoteles from "../pages/hoteles";
import DetalleProducto from "../pages/detalle-producto";
import DetalleCategoria from "../pages/detalle-categoria";
import DetalleClasificacion from '../pages/detalle-clasificacion';
import Template from "../pages/template";
import Chats from '../pages/chats';
import Pago from '../pages/payment';
import TipoCambio from '../pages/tipocambio';
import socket from '../core/socket';
import Proveedores from '../pages/proveedores';
import Costos from '../components/proveedores/proveedor-costos';
import Reportes from '../pages/reportes';
import Banner from '../pages/banner';
import ProductoBanner from '../pages/detalle-banner';
import Agencias from "../pages/agencias";

//locales
import es from 'react-intl/locale-data/es';
addLocaleData([...es]);

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    socket.on('connect', () => {
      const data = { socket: socket.id };
      socket.emit('newAdmin', data);
    })
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          this.props.context.auth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
    return (
      <IntlProvider locale="es">
        <BrowserRouter basename="/">
          <div className="admin flex">
            <div className="column">
              {/*<Header />*/}
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/login" exact component={Login} />
                <PrivateRoute path="/dashboard" exact component={Tablero} />
                <PrivateRoute path="/reservaciones" exact component={Reservaciones} />
                <PrivateRoute path="/clientes" exact component={Clientes} />
                <PrivateRoute path="/usuarios" exact component={Usuarios} />
                <PrivateRoute path="/productos" exact component={Productos} />
                <PrivateRoute path="/clasificaciones" exact component={Clasificaciones}/>
                <PrivateRoute path="/categorias" exact component={Categorias} />
                <PrivateRoute path="/hoteles" exact component={Hoteles} />
                <PrivateRoute path="/template" exact component={Template} />
                <PrivateRoute path="/productos/agregar" exact component={DetalleProducto}/>
                <PrivateRoute path="/productos/modificar/:id" exact component={DetalleProducto} />
                <PrivateRoute path="/categorias/modificar/:id" exact component={DetalleCategoria} />
                <PrivateRoute path="/categorias/agregar" exact component={DetalleCategoria} />
                <PrivateRoute path="/clasificacion/agregar" exact component={DetalleClasificacion}/>
                <PrivateRoute path="/clasificacion/modificar/:id" exact component={DetalleClasificacion} />
                <PrivateRoute path="/chats" exact component={Chats} />
                <PrivateRoute path="/pago" exact component={Pago} />
                <PrivateRoute path="/tipocambio" exact component={TipoCambio} />
                <PrivateRoute path="/proveedores" exact component={Proveedores} />
                <PrivateRoute path="/costos/:id" exact component={Costos} />
                <PrivateRoute path='/reportes' exact component={Reportes} />
                <PrivateRoute path="/banner" exact component={Banner} />
                <PrivateRoute path="/productos/banner/agregar" exact component={ProductoBanner} />
                <PrivateRoute path="/productos/banner/modificar/:id" exact component={ProductoBanner} />
                <PrivateRoute path="/agencias" exact component={Agencias} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

export default Consumer(Routes);
