import React, { Component } from 'react';
import Request from '../../core/httpClient';

const req = new Request();

const opcionInit = { es: '', en: '', pt:'', personas: 0}

class OptionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			loading: false,
			options: [],
			id_opcion: 0,
			option: opcionInit,
			activo: false, 
			id_delete: ''
        }
    }

	componentDidMount() {
		this.loadOptions();
	}

	async loadOptions() {
		const { idProduct } = this.props
		const res = await req.get('/products/get/options/active', {idProduct});
		if(res.options) {
			const options = res.options;
			if(Array.isArray(options)) {
				this.setState({options});
				this.props.setOptions(options);
			}
		} else {
			this.setState({options: []});
		}
	}

    editOption(opcion) {
        this.setState({ edit: true, id_opcion: opcion.id_opcion, option: opcion.opcion, activo: opcion.id_status });
    }

	optionChange(locale, event) {
		const {value} = event.target;
		const {option} = this.state;
		option[locale] = value;
		this.setState({option});
	}

    async editMethod() { 
        this.setState({loading_options: true});
		const data = {
			id_opcion: this.state.id_opcion,
			opcion: this.state.option,
			activo: this.state.activo
		}
		const res = await req.post('/products/edit/option', data);
		if(res.updated) {
			this.setState({option: opcionInit, id_opcion: undefined, edit: false});
			this.loadOptions();
		} else if(res.error) {
			alert(res.message);
		}
		this.setState({loading_options: false});
	}
	
	async deleteOption() {
		this.setState({ delete_message: '', delete_loading: true });
		console.log(this.state.id_delete);
		const res = await req.post('/admin/products/delete/option', { id_opcion: this.state.id_delete });
		console.log(res);
		this.setState({ delete_message: res.message });
		if (res.deleted) {
			const modal = document.getElementById('modal_delete_category');
			if (modal) {
				setTimeout(() => {
					modal.classList.add("hidden");
					this.loadOptions();
				}, 1200);
			}
		}
		this.setState({ delete_loading: false });
	}

    render() {
        return(
            <div className="options-table column justify-center align-center">
                <div className="container align-center column">
                    <div className="ot column">
                        <div className="table-responsive column align-center">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Opción Esp</th>
										<th>Opción Ing</th>
										<th>Opción Por</th>
										{this.props.idCategoria == 31 && <th>Personas</th>}
                                        <th>Editar</th>
										<th>Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ?
                                        <tr className="table-row text-center">
                                            <td colSpan="7">
                                                <div className="column align-center justify-center">
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </div>
                                            </td>
                                        </tr>
										:
										this.state.options.map(option =>
                                        	this.state.edit && this.state.id_opcion == option.id_opcion ?
												<tr className="table-row text-center" key={option.id_opcion}>
													<td>
														<input className="input" value={this.state.option.es} onChange={this.optionChange.bind(this, 'es')} required></input>
													</td>
													<td>
														<input className="input" value={this.state.option.en} onChange={this.optionChange.bind(this, 'en')} required></input>
													</td>
													<td>
														<input className="input" value={this.state.option.pt} onChange={this.optionChange.bind(this, 'pt')} required></input>
													</td>
													{this.props.idCategoria == 31 && 
														<td>
															<input className="input" style={{width: '60%'}} type="number" value={this.state.option.personas} onChange={this.optionChange.bind(this, 'personas')}></input>
														</td>
													}
													<td className="edit">
														<i className="fas fa-save icon-button" onClick={this.editMethod.bind(this)} />
													</td>
													<td className="edit">
														<i className="fas fa-times icon-button" onClick={() => this.setState({ edit: false })} />
													</td>
												</tr>
												:
												<tr className="table-row text-center" key={option.id_opcion}>
													<td>
														{option.opcion.es}
													</td>
													<td>
														{option.opcion.en}
													</td>
													<td>
														{option.opcion.pt}
													</td>
													{this.props.idCategoria == 31 &&
														<td>
															{option.opcion.personas}
														</td>
													}
													<td className="edit">
														<div className="row justify-arround">
															<i className="fas fa-pencil-alt icon-button" onClick={this.editOption.bind(this, option)} />
															{/*<i className="pointer fas fa-save" />
															<i className="pointer fas fa-times" />*/}
														</div>
													</td>
													<td className="edit">
														<i className="fas fa-trash" onClick={() => {
															var modal = document.getElementById('modal_delete_category');
															if (modal) {
																modal.classList.remove("hidden");
																this.setState({ id_delete: option.id_opcion });
															}
														}} />
													</td>
												</tr>
										)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
				<div className="flex modal hidden" id="modal_delete_category">
					<div className="container modal-content column">
						<div className="modal-header justify-center">
							<h2 className="color-white">
								ELIMINAR
                            </h2>
						</div>
						<div className="modal-body column">
							<div className="white-space-32"></div>
							<div className="justify-center">
								{this.state.delete_message}
							</div>
							<div className="white-space-32"></div>
							<div className="row justify-center">
								{this.state.delete_loading ?
									<div className="btn btn-aqua">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<button type="button" className="btn btn-aqua" onClick={this.deleteOption.bind(this)}>
										ACEPTAR
                                    </button>
								}
								&nbsp;
                                <button type="button" className="btn btn-red" onClick={() => {
									const modal = document.getElementById('modal_delete_category');
									if (modal) {
										modal.classList.add("hidden");
									}
								}}>
									CANCELAR
                                </button>
							</div>
							<div className="white-space-16"></div>
						</div>
					</div>
				</div>
            </div>
        )
    }
}

export default OptionsTable;