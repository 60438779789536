import React, { Component } from 'react';
import moment from 'moment-timezone';

class UsersRow extends Component {

    constructor(props) {
        super(props);
    }

    render() {        
        return (
            <tr className="table-row" id="open-modal-order">
                <td>
                    {this.props.tc.usd}
                </td>
                <td>
                    {moment(this.props.tc.fecha).format('DD/MM/YYYY HH:mm:ss')}
                </td>
            </tr>
        );
    }    

}
export default UsersRow;
