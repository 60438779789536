import React, { Component } from 'react';

// Web Componentes
import UsersRow from './userts-row';

class UsersTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numitems: 10
        };
    }

    render() {
        let users = [];
        if (this.props.users) {
            users = this.props.users.slice((this.props.page - 1) * 10, this.props.page * 10);
        }

        let agencia = this.props.agencia ? this.props.agencia : false;

        const style = {tableLayout: 'fixed'};

        return(
            <div className="users-table">
                <div className="table-responsive">
                    <table style={style}>
                        <thead>
                            <tr>
                                <th> ID </th>
                                <th> USUARIO </th>  
                                {agencia && <th>AGENCIA</th>}  
                                <th> OPCIONES </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.loading ?
                                    (
                                        <tr>
                                            <td colSpan={agencia ? '4' : '3'}>
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                    ) : users.map((user, index) => (
                                        <UsersRow key={index} user={user} load={this.props.load} agencias={this.props.agencias} />
                                    ))
                            }
                            {
                                this.props.users_error ?
                                    (
                                        <tr>
                                            <td colSpan={agencia ? '4' : '3'}>{this.props.users_error}</td>
                                        </tr>
                                    ) : (null)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );

    }

}

export default UsersTable;
