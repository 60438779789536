import React from "react";
import { Link } from "react-router-dom";

const CategoryRow = function(clasification) {
  return (
    <tr className="table-row" id="open-modal-order">
      <td>{clasification.id_clasificacion}</td>
      <td>{clasification.clasificacion['es'].clasificacion}</td>
      <td className="edit">
        <Link to={"clasificacion/modificar/" + clasification.id_clasificacion} className="btn">
          <i className="fas fa-pencil-alt" />
        </Link>
      </td>
      <td className="delete">
        <button className="btn" onClick={() => {
          var modal = document.getElementById('modal_delete_category');
          if (modal) {
            modal.classList.remove("hidden");
            clasification.deleteCategory(clasification.id_clasificacion);
          }
        }}>
          <i class="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default CategoryRow;
