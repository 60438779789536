import React, { Component } from "react";
import socket from '../../core/socket';

class EndConversation extends Component {

    /*componentDidMount() {
        let modalNewsLetter = document.getElementById('modal-delete-chat');
        document.getElementById('close-modal-delete').onclick = () => {
            modalNewsLetter.classList.add('fadeOut', 'faster');
            setTimeout(() => {
                modalNewsLetter.classList.add('hidden');
            }, 500);
        }
    }*/

    close() {
        const modal = document.getElementById("modal-end-conversation");
        modal.style.display = "none";
    }

    render() {
        return (
            <div className="modal column justify-center align-center faster hidden" id="modal-end-conversation">
                <div className="container modal-content align-center column" >
                    <div className="column modal-head">
                        <div className="white-space-48"></div>
                        <h3 className="text-center">
                            ¿DESEA FINALIZAR LA CONVERSACIÓN?
                        </h3>
                        <div className="white-space-8"></div>
                        <p className="text-center">
                            Esta acción no puede deshacerse
                        </p>
                        <div className="white-space-48"></div>
                    </div>
                    <div className="modal-controlls">
                        <button className="btn btn-square btn-aqua weight-semi" id="btn-deleteChat" onClick={this.props.endConversation.bind(this)} >
                            <i className="fas fa-trash-alt color-darkBlue"></i> SI
						</button>
                        <button className="btn btn-square btn-red color-white weight-semi" id="close-modal-delete" onClick={this.close.bind(this)}>
                            <i className="fas fa-times color-white"></i> NO
						</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EndConversation;