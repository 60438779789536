import React, { Component } from 'react';
import Aside from "../components/aside/aside";
import Header from "../components/header/header";
import { SwatchesPicker } from 'react-color';
import ModalBannerVP from '../components/modals/banner-vp';
import Request from '../core/httpClient';

const req = new Request();

class DetailProductBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagen: '',
            locationStatus: "none",
            name: '',
            description: '',
            name_en: '',
            description_en: '',
            name_pt: '',
            description_pt: '',
            category: 0,
            status: 0,
            image: '',
            image_pt: '',
            image_en: '',
            imagenvp: '',
            color: 'white',
            categories: [],
            first: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const cat = await req.post('/admin/categories/get');
        if (cat.categories) {
            //console.log(cat.categories);
            this.setState({ categories: cat.categories });
        } 

        if (this.props.match) {
            const params = this.props.match.params;
            if (params.id) {
                const data = {
                    idBProducto: params.id
                }

                const res = await req.post('/admin/products/banner/id', data);
                if (res) {
                    console.log(res);
                    let name = [], description = [], imagen = [], imagenvp = '';
                    if (res.data) {
                        if (res.data.nombre) {
                            name = JSON.parse(res.data.nombre);
                        }
                        if (res.data.descripcion) {
                            description = JSON.parse(res.data.descripcion);
                        }
                        if (res.data.imagen) {
                            imagen = JSON.parse(res.data.imagen);
                        }
                        if (imagen['es'] !== '') {
                            imagenvp = imagen['es'];
                        } else if (imagen['en'] !== '') {
                            imagenvp = imagen['en'];
                        } else {
                            imagenvp = imagen['pt'];
                        }
                        console.log(res.data.colorText);
                        this.setState({ name: name['es'], description: description['es'], name_en: name['en'], description_en: description['en'], name_pt: name['pt'], description_pt: description['pt'], image: imagen['es'], image_en: imagen['en'], image_pt: imagen['pt'], imagenvp, status: res.data.status, category: res.data.categoria, color: res.data.colorText });
                    }
                }

                this.setState({ locationStatus: "modificar" });
            } else {
                this.setState({ locationStatus: "agregar", nuevo: true });
            }
        }
    }

    async handleChange(event, locale) {
        /*const src = event.target;
        let fr = new FileReader();
        fr.onload = e => {
            console.log(e);
            document.getElementById("product-image").src = e.target.result;
            this.setState({ imagen: e.target.result, first: false });
        };

        if (src.files.length > 0) {
            fr.readAsDataURL(src.files[0]);
        }*/

        this.setState({banner_loading: true});

        let files = event.target.files;
		// let reader = new FileReader();
		if(files[0]) {
			const formData = new FormData();
			formData.append('files[]', files[0]);
            formData.append('locale', locale);
            formData.append('type', 'banner');
            const url = process.env.NODE_ENV === 'production' ? 'https://upload.vamoscancun.com/index.php'/*'https://upload.dwitsites.com/index.php'*/:'http://localhost:8000/uploadAPI/index.php';
            const res = await req.postToUrl(url, formData);
            console.log(res);
            if(res.upload) {
                if (locale === 'es') {
                    this.setState({ image: res.url, first: false, imagennew: res.url });
                } else if (locale === 'en') {
                    this.setState({ image_en: res.url, first: false, imagennew: res.url });
                } else if (locale === 'pt') {
                    this.setState({ image_pt: res.url, first: false, imagennew: res.url });
                }
                //this.setState({pdf_url: res.url});
                alert('Imagen cargada correctamente');
            } else {
                alert('Error al cargar imagen');
                console.error(res.message);
                this.setState({ slider: undefined });
            }
            this.setState({ banner_loading: false });
		}
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ banner_loading: true });
        const form = e.target;
        const name = form.product_name.value;
        const description = form.product_description.value;
        const name_en = form.product_name_en.value;
        const description_en = form.product_description_en.value;
        const name_pt = form.product_name_pt.value;
        const description_pt = form.product_description_pt.value;
        const category = form.category.value;

        if (this.state.locationStatus === 'modificar') {
            let status = form.statusPBanner.checked;
            if (status) {
                status = 1;
            } else {
                status = 0;
            }
            const id = this.props.match.params.id;
            const data = { id: id, name: { es: name, en: name_en, pt: name_pt }, description: { es: description, en: description_en, pt: description_pt }, image: {es: this.state.image, en: this.state.image_en, pt: this.state.image_pt}, category: category, status: status, color: this.state.color };
            //this.props.dispatch(action.updateProductBanner(data));
            const res = await req.post('/admin/products/actualizar/banner', data);
            if (res) {
                this.setState({ banner_loading: false });
                if (res.error) {
                    console.log(res.error);
                } else {
                    alert(res.message);
                    window.location.reload();
                }
            }
        } else {
            if (this.state.image === '' && this.state.image_en === '' && this.state.image_pt === '') {
                alert('Es necesario agregar una imagen.');
                this.setState({ banner_loading: false });
            } else {
                const data = { name: { es: name, en: name_en, pt: name_pt }, description: { es: description, en: description_en, pt: description_pt }, image: {es: this.state.image, en: this.state.image_en, pt: this.state.image_pt}, category: category, color: this.state.color };
                console.log(data);

                const res = await req.post('/admin/products/agregar/banner', data);
                if (res) {
                    this.setState({ banner_loading: false });
                    if (res.error) {
                        console.log(res.error);
                    } else {
                        alert(res.message);
                        //window.location.reload();
                    }
                }
            }
        }
    }

    openModalBanner() {
        if (this.state.locationStatus === 'modificar') {
            document.getElementById("modal-banner").classList.remove("hidden");
        } else {
            if (this.state.imagenvp !== '') {
                document.getElementById("modal-banner").classList.remove("hidden");
            }
        }
    }

    onChangeColor(color) {
        console.log(color);
        this.setState({ color: color.hex });
    }

    render() {
        let categories = [];
        if (this.state.categories) {
            categories = this.state.categories;
            console.log(categories);
        }

        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content column align-center">
                            <div className="container column">
                                <div className="white-space-32"/>
                                <div className="title align-center justify-center">
                                    <div className="container">
                                        <h3 className="color-black">
                                            {this.state.locationStatus === "agregar" ?
                                                'AGREGAR PRODUCTO BANNER' : 'MODIFICAR PRODUCTO BANNER'}
                                        </h3>
                                    </div>
                                </div>
                                <div className="white-space-32" />
                                <div className="add-product justify-center">
                                    <div className="container container-add-product column">
                                        <div className="column">
                                            <div className="left column justify-center align-center">
                                                <div className="white-space-32"></div>
                                                <div className="row">
                                                    <div className="product-images column">
                                                        <label htmlFor="banner_img">Imagen (ESP)</label>
                                                        <div className="white-space-8" />
                                                        <div className="principal-img" onClick={() => document.getElementById("upload_image_esp").click()}>
                                                            <img src={this.state.image || "https://via.placeholder.com/400?text=Click+para.subir+imagen"}
                                                                alt="Click para cambiar imagen"
                                                                name="product-image-esp"
                                                                id="product-image-esp" />
                                                            <div className="rd-parallax-gallery">
                                                                <div className="top-text justify-center align-center">
                                                                    <i className="fas fa-upload"></i>
                                                                </div>
                                                            </div>
                                                            <input type="file" name="upload_image_esp" onChange={(event) => this.handleChange(event, 'es')} id="upload_image_esp" style={{ display: 'none' }} accept="image/*" />
                                                        </div>
                                                    </div>
                                                    <div className="product-images column">
                                                        <label htmlFor="banner_img">Imagen (ING)</label>
                                                        <div className="white-space-8" />
                                                        <div className="principal-img" onClick={() => document.getElementById("upload_image_ing").click()}>
                                                            <img src={this.state.image_en || "https://via.placeholder.com/400?text=Click+para.subir+imagen"}
                                                                alt="Click para cambiar imagen"
                                                                name="product-image-ing"
                                                                id="product-image-ing" />
                                                            <div className="rd-parallax-gallery">
                                                                <div className="top-text justify-center align-center">
                                                                    <i className="fas fa-upload"></i>
                                                                </div>
                                                            </div>
                                                            <input type="file" name="upload_image_ing" onChange={(event) => this.handleChange(event, 'en')} id="upload_image_ing" style={{ display: 'none' }} accept="image/*" />
                                                        </div>
                                                    </div>
                                                    <div className="product-images column">
                                                        <label htmlFor="banner_img">Imagen (PT)</label>
                                                        <div className="white-space-8" />
                                                        <div className="principal-img" onClick={() => document.getElementById("upload_image_pt").click()}>
                                                            <img src={this.state.image_pt || "https://via.placeholder.com/400?text=Click+para.subir+imagen"}
                                                                alt="Click para cambiar imagen"
                                                                name="product-image-pt"
                                                                id="product-image-pt" />
                                                            <div className="rd-parallax-gallery">
                                                                <div className="top-text justify-center align-center">
                                                                    <i className="fas fa-upload"></i>
                                                                </div>
                                                            </div>
                                                            <input type="file" name="upload_image_pt" onChange={(event) => this.handleChange(event, 'pt')} id="upload_image_pt" style={{ display: 'none' }} accept="image/*" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<div className="product-images column">
                                                    <div className="principal-img" onClick={() => document.getElementById("upload_image").click()}>
                                                        <img src={this.state.image || "https://via.placeholder.com/400?text=Click+para.subir+imagen"}
                                                            alt="Click para cambiar imagen"
                                                            name="product-image"
                                                            id="product-image" />
                                                        <div className="rd-parallax-gallery">
                                                            <div className="top-text justify-center align-center">
                                                                <i className="fas fa-upload"></i>
                                                            </div>
                                                        </div>
                                                        <input type="file" name="upload_image" onChange={this.handleChange.bind(this)} id="upload_image" style={{ display: 'none' }} onChange={this.previewImage.bind(this)} />
                                                    </div>
                                                </div>*/}
                                                <form className="column container htmlForm-add-product" onSubmit={this.handleSubmit.bind(this)}>
                                                    <div className="white-space-16" />
                                                    <label htmlFor="product_name">Nombre del producto(ESP)</label>
                                                    <div className="white-space-8" />
                                                    <input
                                                        type="text"
                                                        name="product_name"
                                                        id="product_name"
                                                        className="input input-text"
                                                        value={this.state.name || ''}
                                                        onChange={(event) => {
                                                            this.setState({ name: event.target.value });
                                                        }}
                                                        required
                                                    />
                                                    <div className="white-space-16" />
                                                    <label htmlFor="product_description">Descripción(ESP)</label>
                                                    <div className="white-space-8" />
                                                    <textarea
                                                        name="product_description"
                                                        id="product_description"
                                                        rows="5"
                                                        className="input textarea"
                                                        maxLength="800"
                                                        value={this.state.description || ''}
                                                        onChange={(event) => {
                                                            this.setState({ description: event.target.value });
                                                        }}
                                                    />
                                                    <div className="white-space-24" />
                                                    <label htmlFor="product_name_ing">
                                                        Nombre del producto(ING)
                                                    </label>
                                                    <div className="white-space-8" />
                                                    <input
                                                        type="text"
                                                        name="product_name_en"
                                                        id="product_name_en"
                                                        className="input input-text"
                                                        value={this.state.name_en || ''}
                                                        required
                                                        onChange={(event) => {
                                                            this.setState({ name_en: event.target.value });
                                                        }}
                                                    />
                                                    <div className="white-space-16" />
                                                    <label htmlFor="product_description_ing">
                                                        Descripción(ING)
                                                    </label>
                                                    <div className="white-space-8" />
                                                    <textarea
                                                        name="product_description_en"
                                                        id="product_description_en"
                                                        rows="5"
                                                        className="input textarea"
                                                        maxLength="800"
                                                        value={this.state.description_en || ''}
                                                        onChange={(event) => {
                                                            this.setState({ description_en: event.target.value });
                                                        }}
                                                    />
                                                    <div className="white-space-24" />
                                                    <label htmlFor="product_name_pt">
                                                        Nombre del producto(PT)
                                                    </label>
                                                    <div className="white-space-8" />
                                                    <input
                                                        type="text"
                                                        name="product_name_pt"
                                                        id="product_name_pt"
                                                        className="input input-text"
                                                        value={this.state.name_pt || ''}
                                                        required
                                                        onChange={(event) => {
                                                            this.setState({ name_pt: event.target.value });
                                                        }}
                                                    />
                                                    <div className="white-space-16" />
                                                    <label htmlFor="product_description_pt">
                                                        Descripción(PT)
                                                    </label>
                                                    <div className="white-space-8" />
                                                    <textarea
                                                        name="product_description_pt"
                                                        id="product_description_pt"
                                                        rows="5"
                                                        className="input textarea"
                                                        maxLength="800"
                                                        value={this.state.description_pt || ''}
                                                        onChange={(event) => {
                                                            this.setState({ description_pt: event.target.value });
                                                        }}
                                                    />
                                                    <div className="white-space-24"></div>
                                                    <div className="row">
                                                        <div className="column">
                                                            <label htmlFor="product_description_ing">
                                                                Categoría
                                                            </label>
                                                            <div className="white-space-8" />
                                                            <select className="input-select" name="category" id="category" value={this.state.category} onChange={(event) => this.setState({ category: event.target.value })}>
                                                                <option value={0}>
																	SIN CATEGORIA
																</option>
																{categories.map((categorie, index) => (
                                                                    <option key={index} value={categorie.id_categoria}>
                                                                        {categorie.categoria['es'].categoria}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {this.state.locationStatus === 'modificar' ?
                                                            <div className="column align-center justify-center">
                                                                <label htmlFor="product_description_ing">
                                                                    Estatus
                                                            </label>
                                                                <div className="white-space-8"></div>
                                                                <input type="checkbox" checked={parseInt(this.state.status) === 1 ? true : false} onChange={(event) => this.setState({ status: event.target.checked === false ? 0 : 1 })} name="statusPBanner" id="statusPBanner" />
                                                            </div> : null}
                                                    </div>
                                                    <div className="white-space-24"></div>
                                                    <div className="color-text-banner column">
                                                        <label htmlFor="product_description_ing">
                                                            Color de texto
                                                        </label>
                                                        <div className="white-space-16"></div>
                                                        <SwatchesPicker color={this.state.color} onChangeComplete={this.onChangeColor.bind(this)} />
                                                    </div>
                                                    <div className="white-space-24"></div>
                                                    <div>
                                                        <div className="vp">
                                                            <button type="button" className="btn btn-yellow" onClick={this.openModalBanner.bind(this)}>
                                                                <i class="far fa-eye"></i>&nbsp; VISTA PREVIA
                                                            </button>
                                                        </div>
                                                        <div className="add justify-end">
                                                            {this.props.locationStatus === 'modificar' ?
                                                                this.state.banner_loading ?
                                                                    <button type="button" className="btn btn-green">
                                                                        <i className="fas fa-spinner fa-spin"></i>
                                                                    </button>
                                                                    :
                                                                    <button type="submit" className="btn btn-green">
                                                                        <i class="fas fa-check"></i>&nbsp; ACEPTAR
                                                                    </button>
                                                                :
                                                                this.state.banner_loading ?
                                                                    <button type="button" className="btn btn-green">
                                                                        <i className="fas fa-spinner fa-spin"></i>
                                                                    </button>
                                                                    :
                                                                    <button type="submit" className="btn btn-green">
                                                                        <i class="fas fa-check"></i>&nbsp; ACEPTAR
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="white-space-32"></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                        <ModalBannerVP image={this.state.locationStatus === 'modificar' ? this.state.first === true ? this.state.imagenvp : this.state.imagennew : this.state.imagennew} description={this.state.description} color={this.state.color} />
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailProductBanner;