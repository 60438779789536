import React, { Component } from 'react';
import Request from '../../core/httpClient';

const req = new Request();

class UserModal extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            login_error: '',
            error_class: 'label-error',
            agencia: false
        }
    }

    cipher(text) {
        const string64 = new Buffer(text).toString('base64');
        return string64;
    }

    close() {
        const modal = document.getElementById("modal-add-user");
        modal.style.display = "none";
    }

    async registerUser(event) {
        event.preventDefault();
        const { username, password, agencias } = event.target;
        this.setState({loading: true, message: ''});
        const data = {
            usuario: username.value,
            password: this.cipher(password.value),
            id_agencia: this.props.admin ? 0 : parseInt(agencias.value)
        }
        const res = await req.post('/admin/users/new', data);
        if(res) {
            this.setState({message: res.message});
            if(res.inserted) {
                username.value = '';
                password.value = '';
                if (!this.props.admin) {
                    agencias.value = '';
                    this.props.loadUsersAgencia();
                } else {
                    this.props.loadAdmin();
                }

                this.closeModal();
                //alert("Registro Exitoso");
                
                //window.location.reload();
            }
        }
        this.setState({loading: false, message: ''});
    }

    closeModal() {
        const modal = document.getElementById("modal-add-user");
        modal.style.display = 'none';
    }

    render() {
        let agencias = this.props.agencias ? this.props.agencias : [];
        return(
            <div className="flex modal justify-center hidden" id="modal-add-user">
                <div className="container modal-content column">
                    <div className="modal-header justify-between">
                        <div className="column justify-center">
                            <h3 className="text-center">
                                NUEVO USUARIO
                            </h3>
                        </div>
                        {/*<div className="row justify-end">
                            <i className="fas fa-times" onClick={() => document.getElementById('modal-add-user').classList.add('hidden')}></i>
                            &nbsp;
                        </div>*/}
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-16"></div>
                        <form className="modal-body-container column" onSubmit={this.registerUser.bind(this)}>
                            <div className="users-data column">
                                <div className="user auto column">
                                    <label htmlFor="user" className="color-black">
                                        Usuario
                                    </label>
                                    <div className="white-space-8"></div>
                                    <input type="text" name="username" className="input input-text" required/>
                                    <div className="white-space-16"></div>
                                </div>
                                <div className="password auto column">
                                    <label htmlFor="password" className="color-black">
                                        Contraseña
                                    </label>
                                    <div className="white-space-8"></div>
                                    <input type="password" name="password" className="input input-text" required/>
                                    <div className="white-space-16"></div>
                                </div>
                                {!this.props.admin && <div className="agencias auto column">
                                    <label htmlFor="agencias" className="color-black">
                                        Agencias
                                    </label>
                                    <div className="white-space-8"></div>
                                    <select className="input input-select" name="agencias" id="agencias" required>
                                        {agencias.map(agencia => {
                                            return <option value={agencia.id_agencia}>{agencia.agencia}</option>
                                        })}
                                    </select>
                                    <div className="white-space-16"></div>
                                </div>}
                            </div>
                            <div className="white-space-8"></div>
                            <strong className="color-darkGreen" id="register-status">
                                {this.state.message}
                            </strong>
                            <div className="white-space-8"></div>
                            <div className="btn-container-login">
                                <button className="btn btn-square btn-red color-white weight-semi" type="button" id="close-modal-delete" onClick={this.close.bind(this)}>
                                    <i className="fas fa-times color-white"></i> CANCELAR
						        </button>
                                {this.state.loading?
                                    <div className="row align-center justify-center btn btn-aqua">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    :
                                    <button type="submit" className="btn btn-aqua" id="registerUser">
                                        <i className="fas fa-check"></i>&nbsp; REGISTRAR USUARIO
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );

    }
}

export default UserModal;
