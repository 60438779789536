import React, { Component } from "react";

// Web Components
import ProductsTable from "../oders/products-table";
import ClientTable from "../oders/client-table";
import StatusTable from "../oders/status-table";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      products: [],
      accion: "",
      estado: this.props.order.estado
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order !== this.props.order) {
      const data = {
        id_pedido: this.props.order.idPedidos
      };
      this.setState({ loading: true });
    }
  }

  response(res) {
    this.setState({ loading: false });
    if (res.error) {
      console.log(res.message);
      this.setState({ error: res.message });
    } else {
      this.setState({ products: res.data, error: undefined });
    }
  }

  actualizar(event) {
    event.preventDefault();
    const form = document.getElementById("product-detail-form");
    const modal = document.getElementById("detail-question-modal");
    const text = document.getElementById("question-modal-text");
    text.innerHTML = "¿ Actualizar Pedido ?";
    // console.log(form.status.value);

    modal.classList.remove("hidden");
    this.setState({ accion: "ACTUALIZAR" });
  }

  cancelar(event) {
    event.preventDefault();
    document.getElementById("modal-order").classList.add("hidden")
  }

  estadoSelect(event) {
    this.setState({ estado: event });
  }

  actionResponse(message) {
    this.setState({ message: message });
    const modal = document.getElementById("detail-message-modal");
    modal.classList.remove("hidden");
  }

  renderMessage() {
    const modal = document.getElementById("detail-message-modal");
    const modal2 = document.getElementById("modal-order");
    return (
      <div
        className="felx modal justify-center hidden"
        id="detail-message-modal"
      >
        <div className="container modal-content column">
          <div className="modal-body column">
            <div className="white-space-32" />
            <div className="flex justify-center align-center">
              <label>{this.state.message}</label>
            </div>
            <div className="white-space-32" />
            <div className="justify-center">
              <button
                className="btn btn-red"
                onClick={() => {
                  modal.classList.add('hidden');
                  modal2.classList.add('hidden');
                }}
              >
                CERRAR
              </button>
            </div>
            <div className="white-space-32" />
          </div>
        </div>
      </div>
    );
  }

  renderQuestion() {
    const modal = document.getElementById("detail-question-modal");
    return (
      <div
        className="felx modal justify-center hidden"
        id="detail-question-modal"
      >
        <div className="container modal-content column">
          <div className="modal-body column">
            <div className="white-space-32" />
            <div className="justify-center">
              <label id="question-modal-text" />
            </div>
            <div className="white-space-32" />
            <div className="justify-center">
              <button
                className="btn btn-aqua"
                onClick={() =>{}}>
                ACEPTAR
              </button>
              <button
                className="btn btn-red"
                onClick={() => modal.classList.add("hidden")}
              >
                CERRAR
              </button>
            </div>
            <div className="white-space-32" />
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="flex modal justify-center hidden" id="modal-order">
        <div className="container modal-content column">
          <div className="modal-header justify-center">
            <h2 className="color-white">DETALLE DE PEDIDO</h2>            
          </div>
          {this.state.loading ? (
            <div className="modal-body column">
              <div className="white-space-32" />
              <div className="justify-center">
                <i className="fas fa-spinner fa-spin" />
              </div>
              <div className="white-space-32" />
            </div>
          ) : this.state.error ? (
            <div className="modal-body column">
              <div className="white-space-32" />
              <div className="justify-center">{this.state.error}</div>
              <div className="white-space-32" />
            </div>
          ) : (
            <form className="modal-body column" id="product-detail-form">
              <div className="white-space-16" />
              <ProductsTable products={this.state.products} />
              <div className="white-space-32" />
              <ClientTable order={this.props.order} />
              <div className="white-space-32" />
              <StatusTable
                order={this.props.order}
                estadoSelect={this.estadoSelect.bind(this)}
              />
              <div className="btn-container-order-acc-can">
                <div className="btn-container-accept">
                  <button
                    className="btn btn-aqua"
                    onClick={this.actualizar.bind(this)}
                  >
                    <i
                      className={
                        this.props.loading
                          ? "fas fa-spinner fa-spin"
                          : "fas fa-pencil-alt"
                      }
                    />
                    &nbsp; ACTUALIZAR
                  </button>
                </div>
                <div className="btn-container-cancel">
                  <button
                    className="btn btn-red"
                    onClick={this.cancelar.bind(this)}
                  >
                    <i className="fas fa-ban" />
                    &nbsp; CERRAR
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        {this.renderQuestion()}
        {this.renderMessage()}
      </div>
    );
  }
}

export default OrderDetails;
