import React, { Component } from 'react';
import Request from '../core/httpClient';

// Web Components
import Aside from '../components/aside/aside';
import TCTable from '../components/tipocambio/tc-table';
import TCModal from '../components/modals/tipocambio';
import Paginator from '../components/paginator/paginator';
import Header from "../components/header/header";

const req = new Request();

class TipoCambio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            tc: []
        }
    }

    componentDidMount() {
        this.load();
        var modal = document.getElementById('modal-add-tc');
        var btn = document.getElementById("open-modal-add-tc");

        btn.onclick = function () {
            modal.classList.remove("hidden");
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.classList.add("hidden");
            }
        }

        window.onkeydown = (event) => {
            if (event.keyCode === 27) {
                modal.classList.add("hidden");
            } else {
                return 0;
            }
        }
    }

    async load() {
        this.setState({loading: true});
        const res = await req.post('/admin/tc/get', {});
        console.log(res);
        if(res) {
            this.setState({message: res.message});
            if(res.tc) {
                this.setState({tc: res.tc})
            }
        }
        this.setState({loading: false});
    }

    setpage(page) {
        this.setState({ page: page })
    }
    
    render() {
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">
                                            TIPO DE CAMBIO
                                </h3>
                                    </div>
                                    <div className="justify-end">
                                        <button className="btn btn-yellow" id="open-modal-add-tc">
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR
                                        </button>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <TCTable page={this.state.page} tc={this.state.tc} loading={this.state.loading} />
                                <div className="white-space-32"></div>
                                {this.state.tc.length > 10 ?
                                <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.state.tc.length / 10)} /> : null}
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                        <TCModal />
                    </div>
                </div>
            </div>
        );
    }
}

export default TipoCambio;
