import React, { Component } from "react";

// Web Components
import Aside from "../components/aside/aside";
import DetailClasification from "../components/clasifications/detail";
import Header from "../components/header/header";

class DetalleClasificacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationStatus: "none",
      esp: {},
      ing: {}
    };
    const success = this.success.bind(this);
    if (this.props.match.params.id) {
    }
  }

  componentWillMount() {
    let locationPath = this.props.location.pathname;
    if (locationPath.includes("modificar")) {
      this.setState({
        locationStatus: "modificar"
      });
    } else if (locationPath.includes("agregar")) {
      this.setState({
        locationStatus: "agregar"
      });
    } else {
      return this.state.locationStatus;
    }
  }

  success(response) {
    if (response.error) {
      this.setState({ error: response.message });
    } else {
      const esp = response.find(category => category.idioma === "es");
      const ing = response.find(category => category.idioma === "en");
      this.setState({
        esp: esp || {},
        ing: ing || {}
      });
    }
  }

  saveCategory(data) {

  }

  saveResponse(response) {
    if (response.error) {
      this.setState({ error: response.message });
    } else {
      window.location = '/clasificaciones';
    }
  }

  deleteCategory() {

  }

  render() {
    return (
      <div className="workspace">
        <div className="column">
          <Header/>
          <div>
            <Aside />
            <div className="control-content justify-center">
              <div className="container column">
                <div className="white-space-32" />
                <div className="align-center">
                  <div>
                    <h3 className="color-black">
                      {this.state.locationStatus === "agregar"
                        ? "AGREGAR CLASIFICACIÓN"
                        : "MODIFICAR CLASIFICACIÓN"}
                      {this.props.loading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : null}
                    </h3>
                    <label>{this.state.error ? this.state.error : null}</label>
                  </div>
                </div>
                <div className="white-space-16" />
                <DetailClasification
                  categoryStatus={this.state.locationStatus}
                  esp={this.state.esp}
                  ing={this.state.ing}
                  categories={this.props.categories}
                  lines={this.props.lines}
                  saveCategory={this.saveCategory.bind(this)}
                  add_loadig={this.props.add_loadig}
                  add_message={this.props.add_message}
                  deleteCategory={this.deleteCategory.bind(this)}
                />
                <div className="white-space-32" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetalleClasificacion;

