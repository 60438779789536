import React, { Component } from 'react';
import BannerRow from '../banner/banner-row';

class BannerTable extends Component {
    render() {
        let products = [];
        if (this.props.productsBanner) {
            products = this.props.productsBanner.slice((this.props.page - 1) * 10, this.props.page * 10);
        }

        return (
            <div className="products-table">
                <div className="cart-container justify-center">
                    <div className="column">
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="th-total">ID</th>
                                        <th className="th-total">IMAGEN</th>
                                        <th className="th-total">NOMBRE</th>
                                        <th className="th-tax">DESCRIPCIÓN</th>
                                        <th className="th-total">ESTATUS</th>
                                        <th className="th-total">EDITAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((item, index) =>
                                        <BannerRow key={index} product={item} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BannerTable;