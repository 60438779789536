import React, { Component } from 'react';
import Request from '../../core/httpClient';
import IntlNumberInput from 'react-intl-number-input';

const req = new Request();

class UserModal extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            login_error: '',
            error_class: 'label-error'
        }
    }

    async registerTC(event) {
        event.preventDefault();
        this.setState({loading: true, message: ''});
        const data = {
            usd: event.target.usd.value,
        }

        const res = await req.post('/admin/tc/new', data);
        if(res) {
            this.setState({message: res.message});
            if(res.inserted) {
                alert("Registro Exitoso");
                window.location.reload();
            }
        }
        this.setState({loading: false});
    }

    close() {
        const modal = document.getElementById("modal-add-tc");
        modal.style.display = "none";
    }

    render() {
        return(
            <div className="flex modal justify-center hidden" id="modal-add-tc">
                <div className="container modal-content column">
                    <div className="modal-header justify-between">
                        <div className="row justify-center">
                            <h2 className="color-white">
                                TC DIA
                            </h2>
                        </div>
                        {/*<div className="row justify-end">
                            <i className="fas fa-times" onClick={() => document.getElementById('modal-add-tc').classList.add('hidden')}></i>
                            &nbsp;
                        </div>*/}
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-16"></div>
                        <form className="modal-body-container column" onSubmit={this.registerTC.bind(this)}>
                            <div className="users-data justify-center">
                                <div className="column auto">
                                    <label htmlFor="user">
                                        USD
                                    </label>
                                    <div className="white-space-8"></div>
                                    <IntlNumberInput
                                        locale="es-MX"
                                        precision={2}
                                        name="usd"
                                        id="usd"
                                        className="input input-text"
                                        required/>
                                    <div className="white-space-16"></div>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <strong className="color-darkGreen" id="register-status">
                                {this.state.message}
                            </strong>
                            <div className="white-space-8"></div>
                            <div className="btn-container-login">
                                <button className="btn btn-square btn-red color-white weight-semi" type="button" id="close-modal-delete" onClick={this.close.bind(this)}>
                                    <i className="fas fa-times color-white"></i> CERRAR
						        </button>
                                {this.state.loading?
                                    <div className="row justify-center">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    :
                                    <button type="submit" className="btn btn-blue" id="registerUser">
                                        <i className="fas fa-sign-in-alt"></i>&nbsp; REGISTAR
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );

    }
}

export default UserModal;
