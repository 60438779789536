import React from 'react';

// Images
import Images from '../../img/index';

const DashBoardCards = function( props ) {

    return(
        <div className="admin-dashboard-cards gutters">
            <div className="card-products column" style={{ backgroundImage: `url(${Images['snorkel-3-black']})` }}>
                <h2 className="text-center">
                    TOURS
                </h2>
                <div className="white-space-8"></div>
                <h2 className="text-center">
                    { props.loading ? <i className="fas fa-spinner fa-spin"/> : props.products }
                </h2>
            </div>
            <div className="card-orders column" style={{ backgroundImage: `url(${Images['picnic-black']})` }}>
                <h2 className="text-center">
                    RESERVACIONES
                </h2>
                <div className="white-space-8"></div>
                <h2 className="text-center">
                    { props.loading? <i className="fas fa-spinner fa-spin"/> : props.reservations }
                </h2>
            </div>
        </div>
    );

}

export default DashBoardCards;