import React, { Component } from "react";
import { Link } from "react-router-dom";

class HotelRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hotel = [];
        if (this.props.hotel) {
            hotel = this.props.hotel;
            console.log(this.props.hotel);
        }

        return (
            <tr className="table-row" id="open-modal-order">
                <td>{hotel.id_hotel}</td>
                <td>{hotel.nombre}</td>
                <td><input type="checkbox" checked={hotel.id_status === 1 ? true : false} disabled /></td>
                <td>{hotel.zona_name}</td>
                <td className="edit">
                    <button className="btn" onClick={this.props.modalAddHotel.bind(this, 2, hotel)}>
                        <i className="fas fa-pencil-alt" />
                    </button>
                </td>
                <td className="delete">
                    <button className="btn" onClick={() => {
                        var modal = document.getElementById('modal_delete_category');
                        if (modal) {
                            modal.classList.remove("hidden");
                            this.props.deleteCategory(hotel.id_hotel);
                        }
                    }}>
                        <i className="fas fa-trash" />
                    </button>
                </td>
            </tr>
        );
    }
};

export default HotelRow;