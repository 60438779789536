import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import Request from '../core/httpClient';

// Web Components
import Aside from '../components/aside/aside';
import Filters from '../components/products/filters';
import ChatsTable from '../components/chats/chats-table';
import Paginator from '../components/paginator/paginator';
import BottomControl from '../components/chats/bottom-control';
import EndConversation from '../components/modals/endConversation';
import Horarios from '../components/modals/horarios';
import socket from './../core/socket';
import Header from "../components/header/header";

const req = new Request();

class Chats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            buscar: '',
            nombre: '',
            empty: true,
            id: '',
            status: '', 
            mensajes: []
        }
    }

    componentDidMount() {
        this.load({ busqueda: '' });

        socket.on('newClient', (data) => {
            this.load({ busqueda: '' });
        });

        socket.on('conversation_success', (data) => {
            const msg = this.state.mensajes;
            msg.push(data);
            this.setState({ mensajes: msg });
        });

        socket.on('msg_admin', (data) => {
            let mensaje = data
            if(data) {
                mensaje = JSON.parse(mensaje);
                const mensajes = this.state.mensajes;
                if(mensaje.id_chat === this.state.id) {
                    mensajes.push(mensaje);
                    socket.emit('admin_chat_seen', {nuevo: 0, id_chat: this.state.id});
                    this.setState({mensajes});
                }
            }
            this.scrollChat();
            this.load({ busqueda: '' });
        });

        socket.emit('newConnectionAdmin', socket.id);

        socket.on('admin_chat_seen', (data) => {
            console.log(data)
            this.load({ busqueda: '' });
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.id !== prevState.id) {
            this.loadMessages(this.state.id);
            socket.emit('admin_chat_seen', {nuevo: 0, id_chat: this.state.id});
        }
    }

    async load(params) {
        this.setState({ loading: true });
        const data = {
            search: params.busqueda
        };
        const res = await req.post('/admin/chats/buscar', data);
        this.setState({ message: res.message });
        if (res.chats) {
            this.setState({ chats: res.chats, nombre: this.state.nombre });
        }
        this.setState({ loading: false });
    }

    async loadMessages(id) {
        this.setState({ loadingMessages: true });
        const data = {
            id_chat: id
        };
        const res = await req.post('/admin/chats/messages', data);
        if(res.messages){
            
            this.setState({ mensajes: res.messages });
        } else {
            this.setState({ mensajes: [] });
        }
        this.setState({ loadingMessages: false });

        this.scrollChat();
    }

    search(data) {
        this.load(data);
    }

    modalEndConversation() {
        const modal = document.getElementById("modal-end-conversation");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    endConversation(id) {
        const data = { id: id, tipo: 'CERRADO' }
        socket.emit('status_chat', data);

        socket.on('upd_status_success', (data) => {
            const modal = document.getElementById("modal-end-conversation");
            modal.style.display = 'none';
            modal.classList.add('hidden');
            this.setState({ empty: true });
            this.load({ busqueda: '' });
        });
    }

    scrollChat() {
        const chat_messages = document.getElementById('chat_messages');
        if(chat_messages) {
            const nodes = chat_messages.querySelectorAll('div');
            const last = nodes[nodes.length- 1];
            chat_messages.scrollTop = last.scrollIntoView();
        }
    }

    openConversation(chat) {
        this.setState({ nombre: chat.nombre, id: chat.chat_id, status: chat.status, empty: false });
    }

    openHModal() {
        const modal = document.getElementById("modal-horarios");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    render() {
        let name = '', empty = '';
        if (this.state.nombre) {
            name = this.state.nombre;    
        }

        if (this.state.empty) {
            empty = this.state.empty;
        }

        let chats = [];
        if (this.state.chats) {
            chats = this.state.chats.sort((a,b) => {
                if(a.fecha > b.fecha) {
                    return -1;
                }
                if(a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            })
        };
        const bfilt = this.state.buscar.toLowerCase();
        chats = chats.filter(chat => chat.nombre.toLowerCase().indexOf(bfilt) !== -1 || chat.correo.toLowerCase().indexOf(bfilt) !== -1);
        let mensajes = [];
        if(this.state.mensajes) {
            mensajes = this.state.mensajes.sort((a,b) => {
                if(a.fecha_envio < b.fecha_envio) {
                    return -1;
                }
                if(a.fecha_envio > b.fecha_envio) {
                    return 1;
                }
                return 0;
            })
        }

        return (
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <div className="align-between">
                                    <div className="align-center">
                                        <h3 className="color-black">
                                            TODOS LOS CHATS
                                </h3>
                                    </div>
                                    <div className="justify-end">
                                        <button className="btn btn-yellow" onClick={this.openHModal.bind(this)}>HORARIOS</button>
                                    </div>
                                </div>
                                <div className="white-space-24"></div>
                                <div className="chat row-responsive justify-center align-center">
                                    <div className="content-chat-list column">
                                        <div className="white-space-16"></div>
                                        <div className="chat-search align-center">
                                            <i className="fas fa-search"></i>
                                            <input className="input" type="text" placeholder="Buscar" onChange={(event) => this.setState({ buscar: event.target.value })} />
                                        </div>
                                        <div className="list column">
                                            {chats.map((chat, index) =>
                                                <div className="row chat-list" key={index} onClick={this.openConversation.bind(this, chat)}>
                                                    {/*<i className="fas fa-times icon-delete"></i>*/}
                                                    <div className="chat-details column justify-center align-center">
                                                        <div className="row">
                                                            <div className="chat-name auto justify-center align-center">
                                                                <h4 className="color-black">{chat.nombre}</h4>
                                                            </div>
                                                            <div className="chat-status justify-center align-center auto">
                                                                {chat.status === 'CERRADO' ?
                                                                    <i className="fas fa-circle color-gray"></i>
                                                                    : chat.status === 'AUSENTE' ?
                                                                        <i className="fas fa-circle color-orange"></i>
                                                                        : <i className="fas fa-circle color-green"></i>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <h5 className="color-black">{chat.correo}</h5>
                                                        </div>
                                                        <div className="icon-nuevo">
                                                            {chat.nuevo ? <i className="fas fa-comment-dots"></i> : null}
                                                        </div>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                    {/*<ChatsTable page={this.state.page} chats={this.state.chats} loading={this.state.loading} />*/}
                                    {empty ?
                                        <div className="chat-content column justify-center">
                                            <div className="no-messages justify-center">
                                                <h3>NO HAY MENSAJES</h3>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <p className="text-center">
                                                Pulsa <i className="far fa-comments color-pink"></i> para iniciar una conversación.
                                    </p>
                                        </div>
                                        : this.state.status === 'CERRADO' ?
                                            <div className="chat-content column">
                                                <header className="header-chat justify-center">
                                                    <div className="container">
                                                        <div className="header-profile align-center">
                                                            <h4 className="color-white text-center">{name}</h4>
                                                        </div>
                                                    </div>
                                                </header>
                                                <div className="justify-center">
                                                    <div className="container content-chat">
                                                        <div className="column" id="chat_messages">
                                                            <div className="white-space-16"></div>
                                                            {this.state.mensajes.map((item, index) =>
                                                                <div className="message-me justify-end" key={index}>
                                                                    <p className="auto text-left">{item.mensaje}</p>
                                                                </div>)}
                                                            <div className="white-space-16"></div>
                                                            <div className="message-user justify-start">
                                                                <p className="auto text-right">Hello</p>
                                                            </div>
                                                            <div className="white-space-16"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="chat-content column">
                                                <header className="header-chat justify-center">
                                                    <div className="container">
                                                        <div className="header-profile align-center">
                                                            <h4 className="color-white text-center">{name}</h4>
                                                        </div>
                                                        <div className="end-conversation justify-end">
                                                            <button className="btn" onClick={this.modalEndConversation.bind(this)}>
                                                                Finalizar
                                            </button>
                                                        </div>
                                                    </div>
                                                </header>
                                                <div className="content-gral-chat justify-center">
                                                    <div className="content-chat">
                                                        <div className="column" id="chat_messages">
                                                            <div className="white-space-16"></div>
                                                            {mensajes.map((item, index) => {
                                                                if (item.enviando === 'admin') {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div className="message-me justify-end" key={index}>
                                                                                <p className="auto text-left">{item.msg}</p>
                                                                            </div>
                                                                            <div className="white-space-16"></div>
                                                                        </React.Fragment>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div className="message-user justify-start">
                                                                                <p className="auto text-right">{item.msg}</p>
                                                                            </div>
                                                                            <div className="white-space-16"></div>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            })}
                                                            <div className="white-space-24"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="white-space-32"></div>
                                                <BottomControl idChat={this.state.id} />
                                            </div>}
                                </div>
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                        <EndConversation endConversation={this.endConversation.bind(this, this.state.id)} load={this.load.bind(this)} />
                        <Horarios />
                    </div>
                </div>
            </div>
        );
    }
}

export default Consumer(Chats);