import React, { Component } from 'react';
import Aside from "../components/aside/aside";
import Header from "../components/header/header";
import dayjs from 'dayjs';
import Request from '../core/httpClient';
import ReactExport from "react-export-excel";
const req = new Request();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const data = [
	{TOTAL: 500, NOMBRE:'Tour de prueba', VENDIDOS:34, TEST: ""},
	{TOTAL: 600, NOMBRE:'Tour de prueba', VENDIDOS:75},
	{TOTAL: 700, NOMBRE:'Tour de prueba', VENDIDOS:20},
	{TOTAL: 800, NOMBRE:'Tour de prueba', VENDIDOS:80}
]

class Reportes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: dayjs().add(-7, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            tipos: [],
            data: []
        };
    }

    startChange(event) {
        this.setState({ startDate: event.target.value })
    }

    endChange(event) {
        this.setState({ endDate: event.target.value })
    }

    async load() {
        const res = await req.get('/admin/reports/tipos');
        if (res.tipos) {
            this.setState({ tipos: res.tipos })
        }
    }

    async getReport(event) {
        event.preventDefault();
        const startdate = event.target.startdate.value;
        const enddate = event.target.enddate.value;
        const sp = event.target.reportSelect.value;
        
        const data = { start: startdate, end: enddate, sp: sp };
        console.log(data);
        const res = await req.post('/admin/reports/get', data);
        if (res.reports) {
            this.setState({ data: res.reports });
            console.log(res.reports);
        }
    }

    render() {
        let dataSet = [], columns = [], dataSetExcel = [];
        if (this.state.data.length !== 0) {
            columns = Object.keys(this.state.data[0]);
            dataSet = this.state.data.map(row => {
                return (<tr className="table-row">
                    {columns.map(column => <td>{column === 'Tour' ? JSON.parse(row[column]).en : row[column]}</td>)}
                </tr>);
            });   

            dataSetExcel = [
                {
                    columns,
                    data: this.state.data.map(row => {
                        return columns.map(column => column === 'Tour' ? JSON.parse(row[column]).en : row[column])
                    })
                }
            ]
        }
        
        let tiposReportes = [];
        if (this.state.tipos) {
            tiposReportes = this.state.tipos;
        }

        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content reports justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <h3 className="color-black">REPORTES</h3>
                                <div className="white-space-8" />
                                <div className="container-search-order justify-start">
                                    <form className="get-reports" onSubmit={this.getReport.bind(this)}>
                                        <div className="select-report column">
                                            <h5 className="color-black">TIPO:</h5>
                                            <div className="white-space-8" />
                                            <select name="report-select" name="reportSelect" className="input input-select">
                                                {tiposReportes.map((tipo, index) =>
                                                <option key={index} value={tipo.consulta}>{tipo.nombre}</option>)}
                                            </select>
                                        </div>
                                        <div className="data-from column">
                                            <h5 className="color-black">DESDE:</h5>
                                            <div className="white-space-8" />
                                            <input
                                                type="date"
                                                id="startdate"
                                                name="startdate"
                                                className="input input-text"
                                                placeholder="Desde"
                                                value={this.state.startDate}
                                                onChange={this.startChange.bind(this)}
                                            />
                                        </div>
                                        <div className="data-to column">
                                            <h5 className="color-black">HASTA:</h5>
                                            <div className="white-space-8" />
                                            <input
                                                type="date"
                                                id="enddate"
                                                name="enddate"
                                                className="input input-text"
                                                placeholder="Hasta"
                                                value={this.state.endDate}
                                                onChange={this.endChange.bind(this)}
                                            />
                                        </div>
                                        <div className="get-result justify-start align-end">
                                            <button className="btn btn-yellow">
                                                {!this.props.loading ?
                                                    <i className="fas fa-download" />
                                                    : <i className="fas fa-spinner fa-spin" />
                                                }
                                                &nbsp; OBTENER
                                            </button>
                                        </div>
                                    </form>
                                    <div className="get-results justify-end align-end">
                                        <ExcelFile element={
                                            <button type="button" className="btn btn-green">
                                                <i className="far fa-file-excel" />
                                                &nbsp; EXCEL
							                </button>
                                        } filename={'Reporte'}>
                                            <ExcelSheet dataSet={dataSetExcel} name="Reporte" />
                                        </ExcelFile>
                                    </div>
                                </div>
                                <div className="white-space-24"></div>
                                {/*<ReportsTable />*/}
                                <div className="reports-table">
                                    <div className="table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {/**Tabla mariano*/
                                                        columns.map((column, index) => <th key={index}>{column}</th>)
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataSet.map((row, index) => { return (row) })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.load();
    }
}

export default Reportes;