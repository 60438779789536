import React, { Component } from "react";
// Web Components
import Aside from "../aside/aside";
import CostosTable from "../proveedores/costos-table";
import Request from '../../core/httpClient';
import Paginator from "../paginator/paginator";
import Header from '../../components/header/header';

const req = new Request();

class ProveedorCostos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            products: [],
            tours: [],
            proveedor: ''
        };
    }

    componentDidMount() {
        this.load()
        this.loadTours();
    }

    async load() {
        this.setState({loading: true, message: ''});
        if(this.props.match.params) {
            const data = {
                id_proveedor: this.props.match.params.id
            };
            const res = await req.post('/admin/providers/get/tours', data);
            if(res.tours) {
                let tours = res.tours.tours;
                if(tours) {
                    if(tours[0] === '[') {
                        tours = JSON.parse(tours);
                    } else {
                        tours = [];
                    }
                } else {
                    tours = [];
                }
                this.setState({tours, proveedor: res.tours.proveedor});
            }
            this.setState({message: res.message});
        }
        this.setState({loading: false});
    }

    async loadTours() {
        const res = await req.get('/products/home');
        if(res.products) {
            const products = res.products.map(prod => ({id_producto: prod.id_producto, ...JSON.parse(prod.titulo)}));
            this.setState({products});
        }
    }

    modalAddProveedor(id, data) {
        this.setState({ tipo: id, hotel: data });
        const modal = document.getElementById("modal-add-hotel");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    setpage(page) {
        this.setState({ page: page });
    }

    async addTour(e) {
        e.preventDefault();

        const form = e.target;
        let tour = this.state.products.find(prd => parseInt(prd.id_producto) === parseInt(form.tour.value));
        if(tour) {
            const data = {
                tour: tour.es,
                id_producto: form.tour.value,
                costoAdulto: form.adult_price.value,
                costoMenor: form.child_price.value
            };
            const tours = this.state.tours;
            const find = tours.find(tour => tour.id_producto === data.id_producto);
            if(find) {
                Object.assign(find, {costoAdulto: form.adult_price.value, costoMenor: form.child_price.value});
            } else {
                tours.push(data);
            }

            const res = await req.post('/admin/providers/save/tours', {tours, id_proveedor: this.props.match.params.id});
            if (res.updated) {
                this.load();
            }
        }
    }

    render() {
        let products = [];
        if(this.state.products) {
            products = this.state.products.sort((a,b) => {
                if(a.es < b.es ) {
                    return -1;
                }
                if(a.es > b.es ) {
                    return 1;
                }
                return 0;
            })
        }
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">{`ASIGNAR COSTOS A: ${this.state.proveedor}`}</h3>
                                    </div>
                                    <div className="white-space-16" />
                                </div>
                                <div className="white-space-16" />
                                <div className="row">
                                    <div className="left justify-start">
                                        <button
                                            className="btn btn-green" onClick={this.load.bind(this)} >
                                            <i className="fas fa-sync" />
                                            &nbsp; Recargar
                                        </button>
                                    </div>
                                </div>
                                <div className="white-space-16" />
                                <div className="add-tour">
                                    <form onSubmit={this.addTour.bind(this)}>
                                        <div className="select-tour column justify-start">
                                            <h5 className="auto color-black">TOUR: &nbsp;</h5>
                                            <div className="white-space-8" />
                                            <select name="select-tour" name="tour" className="input input-select">
                                                {products.map((item, index) => {
                                                    const titulo = item.es;
                                                    return (
                                                        <option value={item.id_producto} key={index}>{titulo}</option>
                                                    )
                                                }
                                                )}
                                            </select>
                                        </div>
                                        <div className="adult-price column auto">
                                            <h5 className="color-black">COSTO ADULTO: </h5>
                                            <div className="white-space-8" />
                                            <input className="input input-text" name="adult_price" type="text" pattern="(\d{1,6})([\.])(\d{2})" title="$0.00" required placeholder="0.00" />
                                        </div>
                                        <div className="child-price column auto">
                                            <h5 className="color-black">COSTO MENOR: </h5>
                                            <div className="white-space-8" />
                                            <input className="input input-text" name="child_price" type="text" pattern="(\d{1,6})([\.])(\d{2})" title="$0.00" required placeholder="0.00" />
                                        </div>
                                        <div className="button-filter justify-start align-end">
                                            <button className="btn btn-green" type="submit">
                                                <i className="fas fa-check"></i>&nbsp; AGREGAR / ACTUALIZAR
                                    </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="white-space-16" />
                                <CostosTable
                                    loading={this.state.loading}
                                    page={this.state.page}
                                    tours={this.state.tours}
                                    modalAddProveedor={this.modalAddProveedor.bind(this)}
                                    load={this.load.bind(this)}
                                    id_proveedor={this.props.match.params.id} />
                                <div className="white-space-32" />
                                {this.state.tours.length > 10 ?
                                    <Paginator
                                        setpage={this.setpage.bind(this)}
                                        items={Math.ceil(this.state.tours.length / 10)}
                                    /> : null}
                                <div className="row justify-center">
                                    {this.state.message}
                                </div>
                                <div className="white-space-32" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProveedorCostos;