import React, { Component } from 'react';

// Web Components


class ClientTable extends Component {

    render() {
        
        return(
            <div className="justify-center">
                <div className="container">
                    <div className="table-responsive table-client">
                        <table>
                            <thead>
                                <tr>
                                    <th className="th-folio"> ID CLIENTE </th>
                                    <th className="th-status"> DATOS DEL CLIENTE </th>
                                    <th className="th-date"> ENVÍO </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-row">
                                    <td className="table-idclient"> {this.props.order.idCliente} </td>
                                    <td className="table-clientInfo">
                                        {this.props.order.cliente}
                                    </td>
                                    <td className="table-clientAddress"> {this.props.order.direccion} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        );

    }

}

export default ClientTable;