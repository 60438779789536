/**
 *  views.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Controllador de las vistas con las rutas
 * 	@process: 3
*/

import React from 'react';
import Routes from './routes';
// Conexión con el Context API
import { GlobalContext } from "../context";

const View = () => (
    <GlobalContext>
        <Routes></Routes>
    </GlobalContext>
);

export default View;