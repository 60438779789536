import React from "react";
import { Link } from "react-router-dom";

const ChatRow = function (chat) {
    let titulo = '';
    if (chat.titulo) {
        titulo = JSON.parse(chat.titulo);
        titulo = titulo['es'];
    }
    return (
        <tr className="table-row">
            <td className="table-chat-name">{chat.nombre}</td>
            <td className="table-chat-name">{chat.correo}</td>
            <td className="table-chat-name"> ACTIVO </td>
        </tr>
    );
};

export default ChatRow;