/**
 *  app.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
*/

import React from 'react';
import Views from './core/views';

function App() {
  return (
    <Views></Views>
  );
}

export default App;
