/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejador principal del estado global de la aplicación
 */

import React, { Component, createContext } from "react";

// Actions
import { Login, Logout } from './users';

// Instancia del Context, métodos: Provider y Consumer

const Context = createContext();

class GlobalContext extends Component {
	constructor(props) {
		super(props);
		this.state = {
			auth: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			window.localStorage.setItem('vca_ctx', JSON.stringify(this.state));
		}
	}

	componentWillMount() {
		let state = window.localStorage.getItem('vca_ctx');
		if(state) {
			state = JSON.parse(state);
			this.setState(state);
		}
	}

	componentDidMount() {
		this.setState({
			login: Login.bind(this, this),
			logout: Logout.bind(this, this)
		})
	}

	render() {
    	return (
      		<Context.Provider value={this.state}>
        		{ this.props.children }
      		</Context.Provider>
    	);
  	}
}

/**
 * @function: Consumer
 * @description: HOC conector entre el estado global y un componente consumidor.
 * @param: Component => Componente Web
 *
 */

const Consumer = Component => {
	return props => {
    	return (
      		<Context.Consumer>
        		{ context => <Component {...props} context={context} /> }
      		</Context.Consumer>
    	);
  	};
};

export { Consumer, GlobalContext };