import React, { Component } from 'react';
// Web Components
import Aside from "../components/aside/aside";
import Header from "../components/header/header";
import { Link } from 'react-router-dom';
import BannerTable from '../components/banner/banner-table';
import Paginator from '../components/paginator/paginator';
import Request from '../core/httpClient';

const req = new Request();

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products_banner: [],
            page: 1
        }
    }
    
    componentDidMount() {
        this.getBannerProduct();
    }

    async getBannerProduct() {
        this.setState({ products_banner_loading: true });
        const res = await req.get('/admin/products/banner');
        if (res) {
            this.setState({ products_banner: res.data});
        }
        this.setState({ products_banner_loading: false });
    }

    setpage(page) {
        this.setState({ page: page });
    }

    render() {
        let products_banner = [];
        if (Array.isArray(this.state.products_banner)) {
            products_banner = this.state.products_banner
        }

        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">PRODUCTOS EN BANNER</h3>
                                    </div>
                                    <div className="white-space-16" />
                                </div>
                                <div className="white-space-16" />
                                <div className="row">
                                    <div className="left justify-start">
                                        <button
                                            className="btn btn-green" onClick={this.getBannerProduct.bind(this)}>
                                            <i className="fas fa-sync" />
                                            &nbsp; ACTUALIZAR
                                        </button>
                                    </div>
                                    <div className="right justify-end">
                                        <Link to="productos/banner/agregar" className="btn btn-green">
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR PRODUCTO
                                        </Link>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                {this.state.products_banner_loading ?
                                    <div className="justify-center">
                                        <div className="white-space-24"></div>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <div className="white-space-24"></div>
                                    </div> :
                                    <React.Fragment>
                                        <BannerTable page={this.state.page} productsBanner={products_banner} />
                                        {products_banner.length > 10 ? 
                                        <React.Fragment>
                                            <div className="white-space-32"></div>
                                            <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(products_banner.length / 10)} />
                                        </React.Fragment> : null}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Banner;