import React, { Component } from 'react';
import Request from '../../core/httpClient';
//WebComponents
import { cifrar } from '../encrypt/encrypt';

const req = new Request();

class UsersRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            message: '',
            username: '',
            agencia: this.props.user.agencia
        }

        console.log(this.props.user);
    }

    async editMethod() {
        this.setState({loading: true});
        const data = {
            iduser: this.state.userId,
            user: this.state.username,
            idAgencia: this.props.user.agencia !== 0 && this.props.user.agencia !== null ? this.state.agencia : 0
        }
        console.log(data);
        const res = await req.post('/admin/users/edit', data);
        if(res.edited) {
            Object.assign(this.props.user, {usuario: data.user});
            this.props.load();
        }
        this.setState({loading: false, edit: false});
    }

    close(id) {
        document.getElementById(id).classList.add('hidden');
    }

    editUser(id) {
        this.setState({edit: true, userId: id, username: this.props.user.usuario, agencia: this.props.user.agencia });
    }

    openModal(id) {
        const modal = document.getElementById(id);
        if(modal) {
            modal.classList.add('flex');
            modal.classList.remove('hidden');
        }
    }

    async updatePassword(event) {
        event.preventDefault();
        this.setState({passLoading: true, passMessage: ''});
        const form = event.target;
        if(form.newpassword.value !== form.repassword.value) {
            this.setState({passMessage: 'Las contraseñas no coinciden'});
            return;
        }
        const data = {
            iduser: this.props.user.id_usuario,
            password: cifrar(form.newpassword.value)
        }
        const res = await req.post('/admin/users/changepassword', data);
        this.setState({passMessage: res.message});
        if(res.edited) {
            this.props.load();
        }
        this.setState({passLoading: false});
    }

    async deleteUser(event) {
        event.preventDefault();
        this.setState({delLoading: true, delMessage: ''});
        const id = this.props.user.id_usuario;
        console.log(this.props.user);
        const data = {
            iduser: this.props.user.id_usuario,
        }
        const res = await req.post('/admin/users/delete', data);
        this.setState({delMessage: res.message});
        if(res.deleted) {
            //window.location.reload();
            this.props.load();
            this.close(`modal-user-delete-${id}`);
        }
        this.setState({delLoading: false});
    }

    render() {
        return (
            <React.Fragment key={this.props.key}>
                {this.state.edit ?
                    <tr className="table-row" id="open-modal-order">
                        <td>
                            {this.props.user.id_usuario}
                        </td>
                        <td>
                            <input className="input" value={this.state.username} onChange={(event) => this.setState({username: event.target.value})} required id="edit_username"></input>
                        </td>
                        {this.props.user.agencia !== 0 && this.props.user.agencia !== null && <td>
                            <select className="input width-80" name="agencias" id="agencias" value={this.state.agencia} onChange={(item) => this.setState({ agencia: item.target.value })} required>
                                {this.props.agencias.map(agencia => {
                                    return <option value={agencia.id_agencia}>{agencia.agencia}</option>
                                })}
                            </select>
                            </td>}
                        <td className="edit">
                            <i className="fas fa-save icon-button" onClick={this.editMethod.bind(this)}/>
                            &nbsp;
                            <i className="fas fa-times icon-button" onClick={() => this.setState({ edit: false })} />
                        </td>
                    </tr>
                    :
                    <tr className="table-row" id="open-modal-order">
                        <td>
                            {this.props.user.id_usuario}
                        </td>
                        <td>
                            {this.props.user.usuario}
                        </td>
                        {this.props.user.agencia !== 0 && this.props.user.agencia !== null && <td>{this.props.user.nombre_agencia}</td>}
                        <td className="edit">
                            <i className="fas fa-pencil-alt icon-button" onClick={this.editUser.bind(this, this.props.user.id_usuario)}/>
                            &nbsp;
                            <i className="fas fa-key icon-button" onClick={this.openModal.bind(this, `modal-password-${this.props.user.id_usuario}`)} />
                            &nbsp;
                            <i className="fas fa-trash icon-button" onClick={this.openModal.bind(this, `modal-user-delete-${this.props.user.id_usuario}`)} />
                        </td>
                    </tr>
                }
                <tr className="flex modal justify-center hidden modal-password" id={`modal-password-${this.props.user.id_usuario}`}>
                    <td className="modal-content column">
                        <div className="modal-header justify-center">
                            <h3 className="text-center">
                                EDITAR CONTRASEÑA
                            </h3>
                            {/*<div className="row justify-end">
                                <i className="fas fa-times" onClick={() => document.getElementById(`modal-password-${this.props.user.id_usuario}`).classList.add('hidden')}></i>
                                &nbsp;
                            </div>*/}
                        </div>
                        <div className="modal-body column">
                            <div className="white-space-16"></div>
                            <form className="modal-body-container column" onSubmit={this.updatePassword.bind(this)}>
                                <div className="users-data column">
                                    <div className="user column">
                                        <label htmlFor="user" className="text-left">
                                            Nueva Contraseña
                                        </label>
                                        <div className="white-space-8"></div>
                                        <input type="password" name="newpassword" className="input input-text" required minLength="6"/>
                                        <div className="white-space-16"></div>
                                    </div>
                                    <div className="password column">
                                        <label htmlFor="password" className="text-left">
                                            Repetir Contraseña
                                        </label>
                                        <div className="white-space-8"></div>
                                        <input type="password" name="repassword" className="input input-text" required/>
                                        <div className="white-space-16"></div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <strong className="color-darkGreen" id="register-status">
                                    {this.state.passMessage}
                                </strong>
                                <div className="white-space-8"></div>
                                <div className="btn-container-login">
                                    <button className="btn btn-square btn-red color-white weight-semi" type="button" onClick={this.close.bind(this, `modal-password-${this.props.user.id_usuario}`)}>
                                        <i className="fas fa-times color-white"></i> CANCELAR
                                    </button>
                                    {this.state.passLoading?
                                        <div className="row align-center justify-center btn btn-aqua">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div>:
                                        <button type="submit" className="btn btn-aqua">
                                            <i className="fas fa-sign-in-alt"></i>&nbsp; ACTUALIZAR
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </td>
                </tr>
                <tr className="flex modal justify-center hidden modal-password" id={`modal-user-delete-${this.props.user.id_usuario}`}>
                    <td className="modal-content column">
                        <div className="modal-header justify-center">
                            <h3 className="text-center">
                                ELIMINAR USUARIO
                            </h3>
                        </div>
                        <div className="modal-body column">
                            <form className="modal-body-container column" onSubmit={this.deleteUser.bind(this)}>
                                <div className="white-space-32"></div>
                                {this.state.delMessage ? 
                                <strong className="color-darkGreen" id="register-status">
                                    {this.state.delMessage}
                                </strong>
                                : 
                                <h3 className="color-black">¿Desea eliminar usuario?</h3>}
                                <div className="white-space-32"></div>
                                <div className="btn-container-login">
                                    <button className="btn btn-square btn-red color-white weight-semi" type="button" onClick={this.close.bind(this, `modal-user-delete-${this.props.user.id_usuario}`)}>
                                        <i className="fas fa-times color-white"></i> CANCELAR
                                    </button>
                                    {this.state.delLoading ?
                                        <div className="row align-center justify-center btn btn-aqua">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div> :
                                        <button type="submit" className="btn btn-aqua">
                                            <i className="fas fa-sign-in-alt"></i>&nbsp; ACEPTAR
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }    

}
export default UsersRow;
