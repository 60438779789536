import React, { Component } from "react";
import moment from 'moment-timezone';
import socket from '../../core/socket';
import Request from '../../core/httpClient';

const req = new Request();

class EndConversation extends Component {
	constructor(props) {
		super(props);
		this.state={
			horarios: {
				entrada1: '10:00',
				salida1: '10:00',
				entrada2: '10:00',
				salida2: '10:00'
			}
		}
	}

	componentDidMount() {
		socket.on('update_horarios', (data) => {
			this.setState({loading: false, message: data.message});
		});

		this.load();
	}

	async load() {
		const res = await req.post('/admin/chats/horarios', {});
		if(res.horarios) {
			const horarios = {
				entrada1: res.horarios.entrada1,
				salida1: res.horarios.salida1,
				entrada2: res.horarios.entrada2,
				salida2: res.horarios.salida2
			}
			this.setState({horarios});
		}
		this.setState({message: res.message});
	}

    close() {
        const modal = document.getElementById("modal-horarios");
        modal.style.display = "none";
	}
	
	updateHorarios() {
		this.setState({loading: true, message: ''});
		socket.emit('update_horarios', this.state.horarios);
	}

    render() {
        return (
            <div className="modal column justify-center align-center faster hidden" id="modal-horarios">
                <div className="container modal-content align-center column" >
                    <div className="column modal-head">
                        <div className="horarios justify-center column">
							<div className="white-space-16"></div>
							<h3 className="text-center">
								HORARIOS DE ATENCIÓN
                        	</h3>
							<div className="white-space-16"></div>
						</div>
                        <div className="white-space-24"></div>
                        <p className="text-center">
                            <table>
								<thead>
									<tr>
										<th>
											Desde
										</th>
										<th>
											Hasta
										</th>
									</tr>
								</thead>
								<div className="white-space-8"></div>
								<tbody>
									<tr>
										<th>
											<input className="input" type="time"
												value={this.state.horarios.entrada1}
												onChange={(event)=>{
													const horarios = this.state.horarios;
													horarios['entrada1'] = event.target.value;
													this.setState({horarios});
												}}/>
										</th>
										<th>
											<input className="input" type="time"
												value={this.state.horarios.salida1}
												onChange={(event)=>{
													const horarios = this.state.horarios;
													horarios['salida1'] = event.target.value;
													this.setState({horarios});
												}}/>
										</th>
									</tr>
									<div className="white-space-8"></div>
									<tr>
										<th>
											<input className="input" type="time"
												value={this.state.horarios.entrada2}
												onChange={(event)=>{
													const horarios = this.state.horarios;
													horarios['entrada2'] = event.target.value;
													this.setState({horarios});
												}}/>
										</th>
										<th>
											<input className="input" type="time" 
												value={this.state.horarios.salida2}
												onChange={(event)=>{
													const horarios = this.state.horarios;
													horarios['salida2'] = event.target.value;
													this.setState({horarios});
												}}/>
										</th>
									</tr>
								</tbody>
							</table>
                        </p>
						<div className="white-space-16"></div>
						<div className="row justify-center">
							{this.state.message}
						</div>
                        <div className="white-space-24"></div>
                    </div>
                    <div className="modal-controlls">
						<button className="btn btn-square btn-red color-white weight-semi" id="btn-no" onClick={this.close.bind(this)}>
							<i className="fas fa-times color-white"></i> CERRAR
						</button>
						{this.state.loading?
							<button className="btn btn-square btn-aqua weight-semi" id="btn-yes">
								<i className="fas fa-spinner fa-spin"></i>
							</button>
							:
							<button className="btn btn-square btn-aqua weight-semi" id="btn-yes" onClick={this.updateHorarios.bind(this)} >
								<i className="fas fa-check color-darkBlue"></i> GUARDAR
							</button>
						}
                    </div>
                </div>
            </div>
        );
    }
}

export default EndConversation;