import React, { Component } from "react";
import moment from "moment-timezone";
import FormattedNumber from "react-number-format";

class OrdersRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',

    };
  }

  openModalOrder() {
    this.props.selectorder(this.props.order);
    document.getElementById("modal-order").classList.remove("hidden");
  }

  render() {
    let locale = window.localStorage.getItem('cto_locale');
    if (!locale) {
      locale = 'es';
    }
    
    let titulo = '', cliente = '';
    if (this.props.order) {
      titulo = this.props.order.titulo;
      if (titulo) {
        titulo = JSON.parse(titulo);
        titulo = titulo[locale];
      }

      console.log(this.props.order);

      cliente = this.props.order.cliente;
      if (cliente) {
        cliente = JSON.parse(cliente);
      }
    }

    return (
      <tr className="table-row" onClick={this.props.modalReservationDetail.bind(this, this.props.order)} >
        <td className="align-left">{this.props.order.folio}</td>
        <td>{cliente.name}</td>
        <td>{titulo}</td>
        <td>{moment(this.props.order.fecha_tour).tz('America/cancun').format("DD/MM/YYYY")}</td>
        <td>
          <FormattedNumber
            prefix={'$'}
            displayType={'text'}
            value={this.props.order.total}
          />
        </td>
        <td>
          {this.props.order.descuento_adicional ? `${parseInt(this.props.order.descuento_adicional)}%` : 0}
        </td>
        <td className="table-total">{this.props.order.estado}</td>
        <td className="table-total">{this.props.order.confirmacion === 1 ? "SI" : "NO"}</td>
        {/*<td className="table-total total-check">VER DETALLES</td>*/}
      </tr>
    );
  }
}

export default OrdersRow;
