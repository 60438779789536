import React, { Component } from "react";

// Web Component
import ChatRow from "./chat-row";

class ChatsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numitems: 10
        };
    }
    render() {
        let chats = [];
        if (this.props.chats) {
            chats = this.props.chats.slice((this.props.page - 1) * 10, this.props.page * 10);
        }
        return (
            <div className="chats-table">
                <div className="cart-container justify-center">
                    <div className="column">
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="th-name"> NOMBRE </th>
                                        <th> CORREO </th>
                                        <th> STATUS </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.loading ? (
                                        <tr>
                                            <td colSpan="3">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                    ) : null}
                                    {chats.map((chat) => (
                                        <ChatRow key={chat.id_chat} {...chat} />
                                    ))}
                                    {/*{this.props.products_error ? (
                                        <tr>
                                            <td colSpan="4">{this.props.products_error}</td>
                                        </tr>
                                    ) : null}*/}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatsTable;