import React, { Component } from 'react';

// Web Components 
import ClientsRow from '../clients/clients-row';

class ClientsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numitems: 10
        };
    }

    render() {

        let clients = [];        
        if (this.props.clients) {
            clients = this.props.clients.slice((this.props.page - 1) * 10, this.props.page * 10);
        }

        return(            
            <div className="clients-table">
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th> USUARIO </th>
                                <th> NOMBRE </th>
                                <th> EMAIL </th>
                                <th> TELÉFONO </th>
                                <th> DIRECCIÓN </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.loading ? 
                                (
                                    <tr>
                                        <td colSpan="7">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </td>
                                    </tr>
                                ) : ( null )                            
                            }
                            {
                                clients.map((client, index) => (
                                    <ClientsRow key = { index } { ...client } />  
                                ))
                            }
                            {
                                this.props.clients_error ?
                                (
                                <tr>
                                    <td colSpan="7">{this.props.clients_error}</td>
                                </tr> 
                                ) : ( null )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ClientsTable;
