import React, { Component } from "react";
import Request from '../../core/httpClient';

// Web Components
import HotelRow from "./hotel-row";

const req = new Request();

class HotelTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numitems: 10,
            id_delete: ''
        };
    }

    delete(id) {
        this.setState({ id_delete: id });
    }

    async deleteCategory() {
        this.setState({ delete_message: '', delete_loading: true });
        console.log(this.state.id_delete);
        const res = await req.post('/admin/hoteles/delete', { id: this.state.id_delete });
        console.log(res);
        this.setState({ delete_message: res.message });
        if (res.deleted) {
            const modal = document.getElementById('modal_delete_category');
            if (modal) {
                setTimeout(() => {
                    modal.classList.add("hidden");
                    window.location.reload();
                }, 1200);
            }
        }
        this.setState({ delete_loading: false });
    }

    render() {
        let hoteles = [];
        if (this.props.hoteles) {
            hoteles = this.props.hoteles.slice(
                (this.props.page - 1) * 10,
                this.props.page * 10
            );
        }
        return (
            <div className="clients-table">
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th className="th-name">ID</th>
                                <th className="th-name">HOTEL</th>
                                <th className="th-name">ESTADO</th>
                                <th className="th-name">ZONA</th>
                                <th className="th-name">EDITAR</th>
                                <th className="th-name">ELIMINAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.loading ? (
                                <tr>
                                    <td colSpan={3}>
                                        <i className="fas fa-spinner fa-spin" />
                                    </td>
                                </tr>
                            ) : (
                                    hoteles.map((hotel, index) => (
                                        <HotelRow key={index} hotel={hotel} deleteCategory={this.delete.bind(this)} modalAddHotel={this.props.modalAddHotel.bind(this)} />
                                    ))
                                )}
                        </tbody>
                    </table>
                </div>
                <div className="flex modal hidden" id="modal_delete_category">
                    <div className="container modal-content column">
                        <div className="modal-header justify-center">
                            <h2 className="color-white">
                                ELIMINAR
                            </h2>
                        </div>
                        <div className="modal-body column">
                            <div className="white-space-32"></div>
                            <div className="justify-center">
                                {this.state.delete_message}
                            </div>
                            <div className="white-space-32"></div>
                            <div className="row justify-center">
                                {this.state.delete_loading ?
                                    <div className="btn btn-aqua">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    :
                                    <button className="btn btn-aqua" onClick={this.deleteCategory.bind(this)}>
                                        ACEPTAR
                                    </button>
                                }
                                &nbsp;
                                <button className="btn btn-red" onClick={() => {
                                    const modal = document.getElementById('modal_delete_category');
                                    if (modal) {
                                        modal.classList.add("hidden");
                                    }
                                }}>
                                    CANCELAR
                                </button>
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HotelTable;