import React, { Component } from 'react';
import Request from '../../core/httpClient';

const req = new Request();

class AddAgencias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencia: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({
                id_agencia: this.props.agencia.id_agencia,
                agencia: this.props.agencia.agencia
            });
        }
    }

    async addAgencia(tipo, e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            agencia: this.state.agencia
        }
        console.log(data);

        if (tipo === 1) {
            const res = await req.post('/admin/agencias/add', { agencia: this.state.agencia });
            if (res.agencia) {
                console.log(res);
            }
        } else {
            const res = await req.post('/admin/agencias/update', { agencia: this.state.agencia, id_agencia: this.state.id_agencia });
            if (res.agencia) {
                console.log(res);
            }
        }
        

        this.setState({ loading: false });
        this.close();
        this.props.getAgencias();
    }

    close() {
        const modal = document.getElementById("modal-add-agencia");
        modal.style.display = "none";
    }

    render() {
        let edit = this.props.tipo ? this.props.tipo : 1;
        return(
            <div className="modal column justify-center align-center faster hidden" id="modal-add-agencia">
                <form className="container modal-content align-center column" onSubmit={this.addAgencia.bind(this, edit)}>
                    <div className="column modal-head">
                        <div className="white-space-16"></div>
                        <h3 className="text-center">
                            {edit === 1 ? 'AGREGAR AGENCIA' : 'EDITAR AGENCIA'}
                        </h3>
                        <div className="white-space-16"></div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="hotel-data justify-center">
                        <div className="container">
                            <div className="hotel-inputs column">
                                <label>Nombre</label>
                                <div className="white-space-8"></div>
                                <input type="text" name="agencia" value={this.state.agencia ? this.state.agencia : ''} onChange={(event) => { this.setState({ agencia: event.target.value }) }} required />
                                <div className="white-space-8"></div>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="modal-controlls">
                        <button className="btn btn-square btn-red color-white weight-semi" type="button" id="close-modal-delete" onClick={this.close.bind(this)}>
                            <i className="fas fa-times color-white"></i> CANCELAR
                        </button>
                        {this.state.loading ?
                            <button className="btn btn-square btn-blue weight-semi" type="button" id="btn-deleteChat">
                                <i className="fas fa-spinner fa-spin"></i>
                            </button>
                            :
                            <button className="btn btn-square btn-blue weight-semi" type="sumbit" id="btn-deleteChat" >
                                <i className="fas fa-check"></i> ACEPTAR
                            </button>}
                    </div>
                </form>
            </div>
        )
    }
}

export default AddAgencias;