import React, { Component } from 'react';
import AgenciasRow from './agencias_row';
import Request from '../../core/httpClient';

const req = new Request();

class AgenciasTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numitems: 10,
            id_delete: ''
        };
    }

    delete(id) {
        this.setState({ id_delete: id });
    }

    async deleteAgencia() {
        this.setState({ delete_message: '', delete_loading: true });
        console.log(this.state.id_delete);
        const res = await req.post('/admin/agencias/delete', { id: this.state.id_delete });
        console.log(res);
        this.setState({ delete_message: res.message });
        if (res.deleted) {
            const modal = document.getElementById('modal_delete_agencia');
            if (modal) {
                setTimeout(() => {
                    modal.classList.add("hidden");
                }, 1200);
            }
            this.props.getAgencias();
        }
        this.setState({ delete_loading: false });
    }

    render() {
        let agencias = this.props.agencias ? this.props.agencias : [];
        return(
            <div className="agencias-table">
                <div className="table-responsive">
                    <table >
                        <thead>
                            <tr>
                                <th> ID </th>
                                <th> AGENCIA </th>
                                <th> EDITAR </th>
                                <th> ELIMINAR </th>
                            </tr>
                        </thead>
                        <tbody>
                            {agencias.map((agencia, item) => <AgenciasRow agencia={agencia} key={item} deleteAgencia={this.delete.bind(this)} modalAddAgencia={this.props.modalAddAgencia}  />)}
                        </tbody>
                    </table>
                </div>
                <div className="flex modal hidden" id="modal_delete_agencia">
                    <div className="container modal-content column">
                        <div className="modal-header justify-center">
                            <h2 className="color-white">
                                ELIMINAR
                            </h2>
                        </div>
                        <div className="modal-body column">
                            <div className="white-space-32"></div>
                            <div className="justify-center">
                                {this.state.delete_message}
                            </div>
                            <div className="white-space-32"></div>
                            <div className="row justify-center">
                                {this.state.delete_loading ?
                                    <div className="btn btn-aqua">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    :
                                    <button className="btn btn-aqua" onClick={this.deleteAgencia.bind(this)}>
                                        ACEPTAR
                                    </button>
                                }
                                &nbsp;
                                <button className="btn btn-red" onClick={() => {
                                    const modal = document.getElementById('modal_delete_agencia');
                                    if (modal) {
                                        modal.classList.add("hidden");
                                    }
                                }}>
                                    CANCELAR
                                </button>
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AgenciasTable;