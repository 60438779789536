import React, { Component } from 'react';
import Request from '../core/httpClient';

// Web Components
import Aside from '../components/aside/aside';
import UsersTable from '../components/users/users-table';
import UserModal from '../components/modals/user';
import Paginator from '../components/paginator/paginator';
import Header from "../components/header/header";

const req = new Request();

class Usuarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            users: [],
            agencias: [],
            admin: true,
            usersAdmin: [],
            usersAgencia: []
        }
    }

    componentDidMount() {
        this.loadAdmin();
        this.loadUsersAgencia();
        var modal = document.getElementById('modal-add-user');
        var btn = document.getElementById("open-modal-add-user");

        btn.onclick = function () {
            modal.classList.remove("hidden");
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.classList.add("hidden");
            }
        }

        window.onkeydown = (event) => {
            if (event.keyCode === 27) {
                modal.classList.add("hidden");
            } else {
                return 0;
            }
        }

        this.getAgencias();
    }

    async loadAdmin() {
        this.setState({loading: true});
        const res = await req.post('/admin/users/todos', {});
        if(res) {
            this.setState({message: res.message});
            if(res.users) {
                console.log(res.users);
                let usersAdmin = res.users.filter(user => user.agencia === 0 || user.agencia === null);
                this.setState({ users: usersAdmin })
            }
        }
        this.setState({loading: false});
    }

    async loadUsersAgencia() {
        this.setState({ loading_agencia: true });
        const res = await req.post('/admin/users/todos', {});
        if (res) {
            this.setState({ message: res.message });
            if (res.users) {
                console.log(res.users);
                let usersAgencia = res.users.filter(user => user.agencia !== 0 && user.agencia !== null);
                this.setState({ usersAgencia })
            }
        }
        this.setState({ loading_agencia: false });
    }

    addUser(tipo) {
        this.setState({ admin: tipo });
        const modal = document.getElementById("modal-add-user");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    setpage(page) {
        this.setState({ page: page })
    }

    async getAgencias() {
        const res = await req.post('/admin/agencias/get');
        if (res.agencias) {
            this.setState({ agencias: res.agencias });
        }
    }
    
    render() {
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">
                                            ADMINISTRADORES
                                        </h3>
                                    </div>
                                    <div className="justify-end">
                                        <button className="btn btn-green" id="open-modal-add-user" onClick={this.addUser.bind(this, true)}>
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR USUARIO
                                        </button>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <UsersTable page={this.state.page} users={this.state.users} loading={this.state.loading} load={this.loadAdmin.bind(this)} agencias={this.state.agencias} />
                                <div className="white-space-32"></div>
                                {this.state.usersAdmin.length > 10 ?
                                    <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.state.usersAdmin.length / 10)} /> : null}
                                <div className="white-space-32"></div>

                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">
                                            USUARIOS DE AGENCIA
                                        </h3>
                                    </div>
                                    <div className="justify-end">
                                        <button className="btn btn-green" id="open-modal-add-user" onClick={this.addUser.bind(this, false)}>
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR USUARIO
                                        </button>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <UsersTable agencia={true} page={this.state.page} users={this.state.usersAgencia} loading={this.state.loading_agencia} load={this.loadUsersAgencia.bind(this)} agencias={this.state.agencias} />
                                <div className="white-space-32"></div>
                                {this.state.usersAgencia.length > 10 ?
                                    <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.state.usersAgencia.length / 10)} /> : null}
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                        <UserModal admin={this.state.admin} agencias={this.state.agencias} loadAdmin={this.loadAdmin.bind(this)} loadUsersAgencia={this.loadUsersAgencia.bind(this)} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Usuarios;
