import React, { Component } from "react";
import Request from "../../core/httpClient"; 
const req = new Request();

class AddHotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            zona: '',
            estado: '',
            zonas: [], 
            primerZona: ''
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const res = await req.post('/admin/hoteles/zonas');
        console.log(res);
        if (res.zonas) {
            this.setState({ zonas: res.zonas });
            let primerZona = res.zonas[0].id_zona;
            console.log(primerZona);
            this.setState({ primerZona });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({
                nombre: this.props.hotel.nombre,
                zona: this.props.hotel.zona,
                estado: this.props.hotel.id_status
            });
        }
    }

    close() {
        const modal = document.getElementById("modal-add-hotel");
        modal.style.display = "none";
    }

    async addHotel(e) {
        e.preventDefault();

        let data = '';

        if (this.props.tipo === 2) {
            data = {
                tipo: this.props.tipo,
                nombre: this.state.nombre,
                zona: this.state.zona,
                id_status: this.state.estado,
                id_hotel: this.props.hotel.id_hotel
            };
        } else {
            data = {
                tipo: this.props.tipo,
                nombre: this.state.nombre,
                zona: this.state.zona
            };
        }

        console.log(data);

        const res = await req.post('/admin/hoteles/save', data);
        if (res.hotel) {
            console.log(res);
        }

        this.close();
        //this.props.load();
    }

    render() {
        return (
            <div className="modal column justify-center align-center faster hidden" id="modal-add-hotel">
                <form className="container modal-content align-center column" onSubmit={this.addHotel.bind(this)}>
                    <div className="column modal-head">
                        <div className="white-space-16"></div>
                        <h3 className="text-center">
                            AGREGAR HOTEL
                        </h3>
                        <div className="white-space-16"></div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="hotel-data justify-center">
                        <div className="container">
                            <div className="hotel-inputs column">
                                <label>Nombre</label>
                                <div className="white-space-8"></div>
                                <input type="text" name="name" value={this.state.nombre ? this.state.nombre : ''} onChange={(event) => { this.setState({ nombre: event.target.value }) }} required/>
                                <div className="white-space-8"></div>
                                <label>Zona</label>
                                <div className="white-space-8"></div>
                                <select className="input" name="zona" id="zona" value={this.state.zona ? this.state.zona : ''} onChange={(event) => { this.setState({ zona: event.target.value }) }} required>
                                    <option value="">Selecciona un elemento...</option>
                                    {this.state.zonas.map((zona, index) => 
                                        <option value={zona.id_zona} key={index}>{zona.zona}</option>
                                    )}
                                </select>
                                <div className="white-space-8"></div>
                                {this.props.tipo === 2 ?
                                <div className="hotel-status align-center">
                                    <label>Activo</label>
                                    <input className="input" name="status" type="checkbox" id="status" checked={this.state.estado === 1 ? true : false}
                                        onChange={(event) => {
                                            const status = event.target.checked ? 1 : 0;
                                            this.setState({ estado: status });
                                        }} /> 
                                </div> 
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="modal-controlls">
                        <button className="btn btn-square btn-red color-white weight-semi" type="button" id="close-modal-delete" onClick={this.close.bind(this)}>
                            <i className="fas fa-times color-white"></i> CANCELAR
						</button>
                        <button className="btn btn-square btn-blue weight-semi" type="sumbit" id="btn-deleteChat" >
                            <i className="fas fa-check"></i> ACEPTAR
						</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddHotel;