import React, { Component } from "react";
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import socket from '../../core/socket';
import Request from '../../core/httpClient';
import Paginator from '../paginator/paginator';

const req = new Request();
const precioInit = {
	id_precio: 0,
	precio_adulto: 0,
	precio_menor: 0,
	desde: moment().format('YYYY-MM-DD'),
	hasta: moment().format('YYYY-MM-DD'),
	descuento: 0,
	id_opcion: 0
}
class Precios extends Component {
	constructor(props) {
		super(props);
		this.state={
			page: 1,
			precios: [],
			edit: false,
			new: false,
			precio: precioInit,
			options: [], 
			id_delete: ''
		}
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps) {
		if(prevProps.id_producto !== this.props.id_producto && this.props.id_producto > 0) {
			this.load();
		}
		if(this.props.options !== prevProps.options) {
			this.setState({options: this.props.options});
		}
	}

	async load() {
		this.setState({loading: true});
		const data = {
			id_producto: this.props.id_producto
		}
		const res = await req.post('/admin/products/prices/get', data);
		if(res.data) {
			this.setState({precios: res.data || []});
		} else {
			this.setState({precios: []});
		}
		this.setState({loading: false});
	}

    close() {
        const modal = document.getElementById("modal-precios");
        if(modal) {
			modal.classList.add('hidden');
		}
	}
	
	setpage(page) {
        this.setState({page: page})
    }

	checkRange(ini1, fin1, ini2, fin2) {
		// console.log(ini1, fin1, ini2, fin2);
		const momentR = extendMoment(moment);
		const range1 = momentR.range(moment(ini1).format('YYYY-MM-DD 00:00:00'), moment(fin1).format('YYYY-MM-DD 00:00:00'));
		const range2 = momentR.range(moment(ini2).format('YYYY-MM-DD 00:00:00'), moment(fin2).format('YYYY-MM-DD 00:00:00'));
		const inRange = range1.overlaps(range2, { adjacent: true });
		// console.log(inRange);
		return inRange;
	}

	async addPrecio(precio) {
		let fechaExist = false;
		this.state.precios.forEach(precio_obj => {
			const opcionObjeto = precio_obj.id_opcion || "0";
			precio.id_opcion = precio.id_opcion? precio.id_opcion : "0";
			// console.log(opcionObjeto.toString(), precio.id_opcion.toString());
			if(opcionObjeto.toString() === precio.id_opcion) {
				if(this.checkRange(precio.desde, precio.hasta, precio_obj.desde, precio_obj.hasta) && precio.id_precio !== precio_obj.id_precio) {
					fechaExist = true;
				}
			}
		});
		if(fechaExist) {
			alert('Algunas fechas se encuentran dentro de otros registros');
		} else {
			const res = await req.post('/admin/products/prices/add', {...precio, id_producto: this.props.id_producto});
			// console.log(res);
			this.load();
			this.setState({new: false, precio: precioInit});
		}
	}

	async updatePrecios(precio) {
		let fechaExist = false;
		this.state.precios.forEach(precio_obj => {
			const opcionObjeto = precio_obj.id_opcion || "0";
			precio.id_opcion = precio.id_opcion? precio.id_opcion : "0";
			if(opcionObjeto.toString() === precio.id_opcion.toString()) {
				if(this.checkRange(precio.desde, precio.hasta, precio_obj.desde, precio_obj.hasta) && precio.id_precio !== precio_obj.id_precio) {
					fechaExist = true;
				}
			}
		});
		if(fechaExist) {
			alert('Algunas fechas se encuentran dentro de otros registros');
		} else {
			const res = await req.post('/admin/products/prices/update', precio);
			// console.log(res);
			this.load();
			this.setState({edit: false, precio: precioInit});
		}
	}

	changeValue(key, event) {
		// console.log(key, event.target, this.state)
		const precio = this.state.precio;
		precio[key] = event.target.value;
		this.setState({precio});
	}

	async deletePrice() {
		this.setState({ delete_message: '', delete_loading: true });
		console.log(this.state.id_delete);
		const res = await req.post('/admin/products/delete/price', { id_precio: this.state.id_delete });
		console.log(res);
		this.setState({ delete_message: res.message });
		if (res.deleted) {
			const modal = document.getElementById('modal_delete_price');
			if (modal) {
				setTimeout(() => {
					modal.classList.add("hidden");
					this.load();
				}, 1200);
			}
		}
		this.setState({ delete_loading: false });
	}

    render() {
        return (
            <div className="prices-table column justify-center align-center">
                <div className="container align-center column" >
                    {/*<div className="column">
                        <div className="justify-center column">
							<div className="white-space-16"></div>
							<h3 className="text-center">
								PRECIOS POR FECHA
                        	</h3>
							<div className="white-space-16"></div>
						</div>
					</div>*/}
					<div className="column modal-body align-center container">
						<div className="white-space-24"></div>
						<div className="row align-center">
							<div>
								<h4 className="color-black">
									PRECIOS POR FECHA
                        		</h4>
							</div>
							<div className="justify-end">
								<button type="button" className="btn btn-green weight-semi" onClick={() => this.setState({ new: true })}>
									<i className="fas fa-plus color-white"></i> Nuevo
								</button>
							</div>
						</div>
                        <div className="white-space-16"></div>
						<div className="table-responsive column align-center">
							<table>
								<thead>
									<tr>
										<th style={{width: '15%'}}>Variación</th>
										<th>Desde</th>
										<th>Hasta</th>
										<th>Adulto USD</th>
										<th>Menor USD</th>
										<th>Promoción</th>
										<th>Editar</th>
										<th>Eliminar</th>
									</tr>
								</thead>
								<tbody>
									{this.state.loading? 
									<tr className="table-row text-center">
										<td colSpan="8">
											<div className="column align-center justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									this.state.precios.slice(((this.state.page - 1) * 10),(this.state.page * 10)).map(precio =>
									{
										let opcionPrecio = "N/A";
										if (precio.opcion) {
											opcionPrecio = JSON.parse(precio.opcion);
											opcionPrecio = opcionPrecio['es'] || "N/A";
										}
										return this.state.edit && this.state.precio.id_precio == precio.id_precio?
										(<tr className="table-row text-center">
											<td>
												<div className="column align-center">
													<select className="input input-text" value={this.state.precio.id_opcion} onChange={this.changeValue.bind(this, 'id_opcion')}>
														<option value="0">N/A</option>
														{this.state.options.map(option => {
															return <option key={option.id_opcion} value={option.id_opcion}>{option.opcion['es']}</option>
														})}
													</select>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="date" className="input input-text" value={moment(this.state.precio.desde).format('YYYY-MM-DD')} onChange={this.changeValue.bind(this, 'desde')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="date" className="input input-text" value={moment(this.state.precio.hasta).format('YYYY-MM-DD')} onChange={this.changeValue.bind(this, 'hasta')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="number" className="input input-text" value={this.state.precio.precio_adulto || 0} onChange={this.changeValue.bind(this, 'precio_adulto')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="number" className="input input-text" value={this.state.precio.precio_menor || 0} onChange={this.changeValue.bind(this, 'precio_menor')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="number" className="input input-text" value={this.state.precio.descuento || 0} onChange={this.changeValue.bind(this, 'descuento')}></input>
												</div>
											</td>
											<td className="edit">
												<i className="pointer fas fa-save" onClick={this.updatePrecios.bind(this, precio)} />
											</td>
											<td className="edit">
												<i className="pointer fas fa-times" onClick={() => { this.setState({ precio: precioInit, edit: false }); this.load() }} />
											</td>
										</tr>)
										:
										(<tr className="table-row text-center" key={precio.id_precio}>
											<td>{opcionPrecio || 'N/A'}</td>
											<td>{moment(precio.desde).format('DD/MM/YYYY')}</td>
											<td>{moment(precio.hasta).format('DD/MM/YYYY')}</td>
											<td>{precio.precio_adulto}</td>
											<td>{precio.precio_menor}</td>
											<td>{precio.descuento}</td>
											<td className="edit">
												<i className="pointer fas fa-pencil-alt" onClick={() => this.setState({precio, edit: true})} />
											</td>
											<td className="edit">
												<i className="fas fa-trash" onClick={() => {
													var modal = document.getElementById('modal_delete_price');
													if (modal) {
														modal.classList.remove("hidden");
														this.setState({ id_delete: precio.id_precio });
													}
												}} />
											</td>
										</tr>)
									}
									)}
									{
										this.state.new && 
										<tr className="table-row text-center">
											<td>
												<div className="column align-center">
													<select className="input input-text" value={this.state.precio.id_opcion} onChange={this.changeValue.bind(this, 'id_opcion')}>
														<option value="0">N/A</option>
														{this.state.options.map(option => {
															// console.log(option);
															return <option key={option.id_opcion} value={option.id_opcion}>{option.opcion['es']}</option>
														})}
													</select>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="date" className="input input-text" value={moment(this.state.precio.desde).format('YYYY-MM-DD')} onChange={this.changeValue.bind(this, 'desde')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="date" className="input input-text" value={moment(this.state.precio.hasta).format('YYYY-MM-DD')} onChange={this.changeValue.bind(this, 'hasta')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="number" className="input input-text" value={this.state.precio.precio_adulto || 0} onChange={this.changeValue.bind(this, 'precio_adulto')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="number" className="input input-text" value={this.state.precio.precio_menor || 0} onChange={this.changeValue.bind(this, 'precio_menor')}></input>
												</div>
											</td>
											<td>
												<div className="column align-center">
													<input type="number" className="input input-text" value={this.state.precio.descuento || 0} onChange={this.changeValue.bind(this, 'descuento')}></input>
												</div>
											</td>
											<td className="edit">
												<i className="pointer fas fa-save" onClick={this.addPrecio.bind(this, this.state.precio)} />
											</td>
											<td className="edit">
												<i className="pointer fas fa-times" onClick={() => { this.setState({ new: false, precio: precioInit }); this.load() }} />
											</td>
										</tr>
									}
								</tbody>
							</table>
							{this.state.precios.length > 10 ?
							<Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.state.precios.length / 10)} /> : null}
						</div>
						<div className="white-space-16"></div>
						<div className="row justify-center">
							{this.state.message}
						</div>
                        <div className="white-space-24"></div>
                    </div>
                </div>
				<div className="flex modal hidden" id="modal_delete_price">
					<div className="container modal-content column">
						<div className="modal-header justify-center">
							<h2 className="color-white">
								ELIMINAR
                            </h2>
						</div>
						<div className="modal-body column">
							<div className="white-space-32"></div>
							<div className="justify-center">
								{this.state.delete_message}
							</div>
							<div className="white-space-32"></div>
							<div className="row justify-center">
								{this.state.delete_loading ?
									<div className="btn btn-aqua">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
									:
									<button type="button" className="btn btn-aqua" onClick={this.deletePrice.bind(this)}>
										ACEPTAR
                                    </button>
								}
								&nbsp;
                                <button type="button" className="btn btn-red" onClick={() => {
									const modal = document.getElementById('modal_delete_price');
									if (modal) {
										modal.classList.add("hidden");
									}
								}}>
									CANCELAR
                                </button>
							</div>
							<div className="white-space-16"></div>
						</div>
					</div>
				</div>
            </div>
        );
    }
}

export default Precios;