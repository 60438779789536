import React, { Component } from 'react';

class Template extends Component {    
    render() {
        let error = prompt('La página no existe, Presiona CANCELAR para regresar a Inicio');if(error === 'start'){}else{window.location='dashboard';}
        return (<iframe src="https://goo.gl/5cpPbX" width="600" height="450" frameborder="no" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" scrolling="no"></iframe>
        )
    }
}

export default Template;