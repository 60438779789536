import React, { Component } from "react";
import Request from '../../core/httpClient';
import { withRouter } from 'react-router-dom';

const req = new Request();

class DetailClasification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clasification: {
        es: { clasificacion: "" },
        en: { clasificacion: "" },
        pt: { clasificacion: ""}
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({loading: true, message: ''});
    const match = this.props.match;
    if(match.params.id) {
      const res = await req.post(`/admin/clasifications/get/${match.params.id}`);
      this.setState({message: res.message});
      if(res.clasificacion) {
        this.setState({clasification: res.clasificacion.clasificacion, idClasificacion: res.clasificacion.id_clasificacion});
      }
    }
    this.setState({loading: false});
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({message: '', loading: true});
    const data = {
      idClasificacion: this.state.idClasificacion || 0,
      clasificacion: this.state.clasification
    };
    const res = await req.post('/admin/clasifications/save', data);
    console.log(res);
    if(res.clasification) {
      window.location = `/clasificacion/modificar/${res.clasificacion.id_clasificacion}`;
    }
    this.setState({message: res.message, loading: false});
  }

  inputChange(key, event) {
    const clasification = this.state.clasification;
    Object.assign(clasification[key], { clasificacion: event.target.value });
    this.setState({ clasification });
  }

  render() {
    return (
      <div className="add-category justify-center">
        <div className="container-add-product column align-center">
          <div className="row container">
            <div className="column justify-center">
              <form className="column gutters htmlForm-add-product" onSubmit={this.handleSubmit.bind(this)}>
                <div className="white-space-16" />
                <label htmlFor="clasification_name">
                  Nombre de la clasificación(ESP)
                </label>
                <div className="white-space-8" />
                <input type="text" name="clasification_name" id="category_name" className="input input-text" required value={this.state.clasification.es.clasificacion || ""} onChange={this.inputChange.bind(this, 'es')} />
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la clasificación(ING)
                </label>
                <div className="white-space-8" />
                <input type="text" name="category_name_ing" id="category_name_ing" className="input input-text" required value={this.state.clasification.en.clasificacion || ""} onChange={this.inputChange.bind(this, 'en')} />
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la clasificación(PRT)
                </label>
                <div className="white-space-8" />
                <input type="text" name="clasification_name" id="clasification_name" className="input input-text" required value={this.state.clasification.pt.clasificacion || ""} onChange={this.inputChange.bind(this, 'pt')} />
                <div className="white-space-16" />
                <div className="row justify-center">
                  {this.state.message}
                </div>
                <div className="white-space-16" />
                <div className="btn-container-publish justify-center">
                  {this.state.loading?
                    <div className="row justify-center">
                      <i className="fas fa-spinner fa-spin" />
                    </div>
                    : this.props.categoryStatus === "agregar" ?
                    <button className="btn btn-green" type="submit">
                      <i className="fas fa-th-large" />
                      &nbsp; PUBLICAR CLASIFICACIÓN
                    </button> :
                    <button className="btn btn-yellow" type="submit">
                        <i className="fas fa-th-large" />
                        &nbsp; ACTUALIZAR CLASIFICACIÓN
                    </button>
                  }
                </div>                
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DetailClasification);
