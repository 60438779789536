import React, { Component } from "react";
import Request from '../../core/httpClient';

const req = new Request();

class CostosRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        }
    }

    editTour(data) {
        this.setState({ edit: data });
    }

    async addTour(e) {
        e.preventDefault();
        const form = e.target;
        let tour = this.state.products.find(prd => parseInt(prd.id_producto) === parseInt(form.tour.value));
        if(tour) {
            const data = {
                tour: tour.es,
                id_producto: form.tour.value,
                costoAdulto: form.adult_price.value,
                costoMenor: form.child_price.value
            };
            const tours = this.state.tours;
            const find = tours.find(tour => tour.id_producto === data.id_producto);
            if(find) {
                Object.assign(find, {costoAdulto: form.adult_price.value, costoMenor: form.child_price.value});
            } else {
                tours.push(data);
            }

            const res = await req.post('/admin/providers/save/tours', tours);
            if (res.updated) {
                this.load();
            }
        }
    }

    render() {
        return (
            <tr className="table-row-provider" id="open-modal-order">
                <React.Fragment>
                    <td>{this.props.id_producto}</td>
                    <td>{this.props.tour}</td>
                    <td>$ {parseFloat(this.props.costoAdulto).toFixed(2)}</td>
                    <td>$ {parseFloat(this.props.costoMenor).toFixed(2)}</td>
                    <td className="delete">
                        <button className="btn" onClick={() => {
                            var modal = document.getElementById('modal_delete_costo');
                            if (modal) {
                                modal.classList.remove("hidden");
                                this.props.delete(this.props.id_producto);
                            }
                        }}/*onClick={this.deleteTour.bind(this)}*/>
                            <i className="fas fa-trash" />
                        </button>
                    </td>
                </React.Fragment>
            </tr>
        );
    }
};

export default CostosRow;