import React, { Component } from 'react';

class ModalSliderVP extends Component {
    closeModal() {
        document.getElementById("modal-banner").classList.add("hidden");
    }

    render() {
        let img = '';
        if (this.props.image) {
            img = this.props.image;
        }

        return (
            <div className="flex modal justify-center hidden" id="modal-banner">
                <div className="banner-content modal-content column">
                    <div className="modal-body-banner">
                        <div className="info-product column justify-center align-center" style={{ backgroundImage: `url(${img})` }}>
                        </div>
                        <div className="close-banner justify-center">
                            <a onClick={this.closeModal.bind(this)}><i class="fas fa-times"></i></a>
                            {/*<i class="fas fa-times-circle"></i>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalSliderVP;