import React from "react";
import { Link } from "react-router-dom";

const CategoryRow = function(category) {
  return (
    <tr className="table-row" id="open-modal-order">
      <td>{category.id_categoria}</td>
      <td>{category.categoria['es'].categoria}</td>
      <td className="edit">
        <Link to={"categorias/modificar/" + category.id_categoria} className="btn">
          <i className="fas fa-pencil-alt" />
        </Link>
      </td>
      <td className="delete">
        <button className="btn" onClick={() => {
          var modal = document.getElementById('modal_delete_category');
          if (modal) {
            modal.classList.remove("hidden");
            category.deleteCategory(category.id_categoria);
          }
        }}>
          <i class="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default CategoryRow;
