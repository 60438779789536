import React from "react";
import moment from "moment-timezone";

const SummaryRow = function(props) {
  let locale = window.localStorage.getItem('cto_locale');
  if (!locale) {
    locale = 'es';
  }
  let titulo = '';
  if (props.row) {
    titulo = props.row.titulo;
    if (titulo) {
      titulo = JSON.parse(titulo);
      titulo = titulo[locale];
    }
  }

  return (
    <tr class="table-row">
      <td>{titulo}</td>
      <td>
        {moment(props.row.fecha_tour).tz('America/cancun').format("DD/MM/YYYY")}
      </td>
      <td>
        {props.row.total}
      </td>
    </tr>
  );
};

export default SummaryRow;
