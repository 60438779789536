import React from 'react';
import { Link } from 'react-router-dom';

const Aside = function() {
    return(
        <div className="aside column">
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-tachometer-alt"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/dashboard">
                        TABLERO
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-coins"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/pago">
                        PAGO
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-box-open"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/reservaciones">
                        RESERVACIONES
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-th-large"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/clasificaciones">
                        CLASIFICACIONES
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-th-large"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/categorias">
                        CATEGORIAS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-hotel"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/hoteles">
                        HOTELES
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-building"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/agencias">
                        AGENCIAS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-cubes"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/productos">
                        PRODUCTOS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto justify-center align-center">
                    <i className="far fa-square"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/banner">
                        BANNER
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-hand-holding-usd"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/proveedores">
                        PROVEEDORES
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-comments"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/chats">
                        CHATS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-money-bill-alt"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/tipocambio">
                        TIPO CAMBIO
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-users-cog"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/usuarios">
                        USUARIOS ADMIN
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto justify-center align-center">
                    <i className="fas fa-file-excel"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/reportes">
                        REPORTES
                    </Link>
                </div>
            </div>
            {/*<div className="menu-element">                
                <div className="menu-element-name align-center">
                    <Link to="/template">                        
                    </Link>
                </div>
            </div>*/}
        </div>
    );

}

export default Aside;