import React, { Component } from "react";
//import IntlNumberInput from 'react-intl-number-input';
import Request from '../../core/httpClient';
import socket from '../../core/socket';
import Precios from '../../components/modals/precios';
import Options from '../products/options';
import ModalSliderVP from '../../components/modals/slider-vp';

const req = new Request();

class DetailProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			esp: {},
			ing: {},
			prt: {},
			precio_adulto: '',
			precio_menor: '',
			promocion: '',
			eliminando: false,
			step: 1,
			categories: [],
			clasifications: [],
			providers: [],
			idCategoria: 1,
			idClasificacion: 1,
			idProveedor: 0,
			idProduct: 0,
			relevante: 0,
			images: [],
			options: [],
			new_option: {
				es: '',
				en: '',
				pt: ''
			}
		};
	}

	componentDidMount() {
		this.load();
	}

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({
        esp: this.props.esp,
        ing: this.props.ing,
        prt: this.props.prt,
        idCategoria: this.props.product.categoria,
        idClasificacion: this.props.product.clasificacion,
        relevante: this.props.product.relevante,
        precio_adulto: this.props.product.precio_adulto,
        precio_menor: this.props.product.precio_menor,
        promocion: this.props.product.descuento,
        idProduct: this.props.product.id_producto,
        activo: this.props.product.id_status,
        idProveedor: this.props.product.proveedor,
        link: this.props.product.link,
        limit: this.props.product.dias_reserva,
        //pdf_url: this.props.product.pdf,
        adult_age: this.props.product.edad_adultos,
        child_age: this.props.product.edad_menores,
        fechas_bloq: this.props.product.fechas_bloqueadas,
        descuento: this.props.product.descuento_agente,
        agenciaCheck: this.props.product.agencia
        
      });
    }
    if(this.props.product.id_producto !== prevProps.product.id_producto) {
      //Firebase
      this.loadImages();
    }
    if(this.props.productStatus !== prevProps.productStatus && this.props.productStatus === 'agregar') {
      for(let index=0; index <= 4; index++) {
        const images = this.state.images;
        images[index] = 'https://via.placeholder.com/400?text=Click+para.subir+imagen';
        this.setState({images});
      }
    }
  }

	async load() {
		const cat = await req.post('/admin/categories/get');
		if(cat.categories) {
		//console.log(cat.categories);
		this.setState({categories: cat.categories});
		}
		const clas = await req.post('/admin/clasifications/get');
		if(clas.clasifications) {
		this.setState({clasifications: clas.clasifications});
		}
		const prov = await req.post('/admin/providers/get');
		if(prov.providers) {
		this.setState({providers: prov.providers});
		}
	}

  async loadImages() {
    const idProd = this.props.product.id_producto;
      if(idProd) {
        for(let index=0; index <= 4; index++) {
          const images = this.state.images;
          const storage = window.firebase.storage().ref();
          const ref = storage.child(`productos/${idProd}/${index}.jpg`);
          ref.getDownloadURL()
          .then(url => {
            images[index] = url;
            this.setState({images});
          })
          .catch(()=> {
            images[index] = 'https://via.placeholder.com/400?text=Click+para.subir+imagen';
            this.setState({images});
          });
        }
      }
  }

  async handleSubmit(event) {
    event.preventDefault();

  	this.setState({saveLoading: true});

    const form = document.getElementById('product-form');

    let titulo = { es: this.state.esp.titulo, en: this.state.ing.titulo, pt: this.state.prt.titulo };
    titulo = JSON.stringify(titulo);

    let descripcion = { es: this.state.esp.descripcion, en: this.state.ing.descripcion, pt: this.state.prt.descripcion };
    descripcion = JSON.stringify(descripcion);

    let descripcion_corta = { es: this.state.esp.descripcion_corta, en: this.state.ing.descripcion_corta, pt: this.state.prt.descripcion_corta};
    descripcion_corta = JSON.stringify(descripcion_corta);

    let incluye = { es: this.state.esp.incluye, en: this.state.ing.incluye, pt: this.state.prt.incluye};
    incluye = JSON.stringify(incluye);

    let noincluye = { es: this.state.esp.noincluye, en: this.state.ing.noincluye, pt: this.state.prt.noincluye};
    noincluye = JSON.stringify(noincluye);

    let recomendaciones = { es: this.state.esp.recomendaciones, en: this.state.ing.recomendaciones, pt: this.state.prt.recomendaciones};
    recomendaciones = JSON.stringify(recomendaciones);

    let pdf = {es: this.state.esp.pdf, en: this.state.ing.pdf, pt: this.state.prt.pdf };
    pdf = JSON.stringify(pdf);

    let slider = {es: this.state.esp.slider, en: this.state.ing.slider, pt: this.state.prt.slider};
    slider = JSON.stringify(slider);

    //Imágenes
    const imgArray = [];
    if (this.props.productStatus === 'modificar') {
      this.state.images.forEach(async (image, index) => {
        if (image !== "https://via.placeholder.com/400?text=Click+para.subir+imagen" && image.indexOf('data:image') !== -1) {
          const idProd = this.props.product.id_producto;
          const storage = window.firebase.storage().ref();
          const ref = storage.child(`productos/${idProd}/${index}.jpg`);
          const imageURL = await ref.putString(image, 'data_url').then(async snapshot => {
            const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
              return downloadURL;
            });
            return url
          });
          imgArray[index] = imageURL;
          await req.post('/admin/products/update/image', { id: this.state.idProduct, image: imgArray[0] });
        } else if (image.indexOf('firebase') !== -1) {
          imgArray[index] = image;
          await req.post('/admin/products/update/image', { id: this.state.idProduct, image: imgArray[0] });
        }
      });
    }

    let idProd = 0;
    console.log(this.state.options);

    if (this.props.productStatus === 'agregar') {
      const data = {
        titulo: titulo,
        descripcion: descripcion,
        descripcion_corta: descripcion_corta,
        precio_adulto: this.state.precio_adulto,
        precio_menor: this.state.precio_menor,
        descuento: this.state.promocion,
        relevante: this.state.relevante,
        categoria: this.state.idCategoria,
        clasificacion: this.state.idClasificacion,
        proveedor: this.state.idProveedor,
        activo: this.state.activo,
        incluye,
        noincluye,
        recomendaciones,
        pdf,
        link: this.state.link,
        limit: this.state.limit,
        //pdf: this.state.pdf_url,
        adult_age: this.state.adult_age,
        child_age: this.state.child_age,
        fechas_bloqueadas: this.state.fechas_bloq || '',
        slider,
        descount: this.state.descuento,
        agencia: this.state.agenciaCheck
      };

      const product = await req.post('/admin/products/agregar', data);
      if (product) {
        if (product.insert) {
          //console.log(product);
          idProd = product.id;
          //console.log(idProd);
          this.state.images.forEach(async (image, index) => {
            if (image !== "https://via.placeholder.com/400?text=Click+para.subir+imagen" && image.indexOf('data:image') !== -1) {
              const storage = window.firebase.storage().ref();
              const ref = storage.child(`productos/${idProd}/${index}.jpg`);
              const imageURL = await ref.putString(image, 'data_url').then(async snapshot => {
                const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
                  return downloadURL;
                });
                return url
              });
              imgArray[index] = imageURL;
              await req.post('/admin/products/update/image', { id: idProd, image: imgArray[0] });
            } else if (image.indexOf('firebase') !== -1) {
              imgArray[index] = image;
              await req.post('/admin/products/update/image', { id: idProd, image: imgArray[0] });
            }
          });

          if (this.state.options.length === 0) {
            let new_option = {
              es: this.state.esp.titulo,
              en: this.state.ing.titulo,
              pt: this.state.prt.titulo
            }

            const res = await req.post('/products/add/option', { new_option, idProduct: idProd });
            if (res.inserted) {
              this.setState({ new_option: { es: '', en: '', pt: '', personas: '' } });
              this.optionsChild.loadOptions();
            }
          }

          alert(product.message);
          window.location = '/productos';
        }
      }
    } else {
      const data = {
        titulo: titulo,
        descripcion: descripcion,
        descripcion_corta: descripcion_corta,
        precio_adulto: this.state.precio_adulto,
        precio_menor: this.state.precio_menor,
        descuento: this.state.promocion,
        id_categoria: this.state.idCategoria,
        id_clasificacion: this.state.idClasificacion,
        id_producto: this.state.idProduct,
        relevante: this.state.relevante,
        proveedor: this.state.idProveedor,
        activo: this.state.activo,
        incluye,
        noincluye,
        recomendaciones,
        pdf,
        link: this.state.link,
        limit: this.state.limit,
        //pdf: this.state.pdf_url,
        adult_age: this.state.adult_age,
        child_age: this.state.child_age,
        fechas_bloqueadas: this.state.fechas_bloq || '',
        slider,
        descount: this.state.descuento,
        agencia: this.state.agenciaCheck
      };

      //console.log(data);

      const product = await req.post('/admin/products/update', data);
      console.log("Product result: ", product);
      if (product.updated) {
        console.log(this.state.options);
        await req.post('/admin/products/update/image', { id: this.state.idProduct, image: imgArray[0] });

        const resOptions = await req.get('/products/get/options/active', {idProduct: this.state.idProduct});
        if (resOptions) {
          if(resOptions.options) {
            console.log(resOptions.options)
            if (resOptions.options.length === 0) {
              let new_option = {
                es: this.state.esp.titulo,
                en: this.state.ing.titulo,
                pt: this.state.prt.titulo
              }

              const res = await req.post('/products/add/option', { new_option, idProduct: this.state.idProduct });
              if (res.inserted) {
                this.setState({ new_option: { es: '', en: '', pt: '', personas: '' } });
                //this.optionsChild.loadOptions();
              }
            }
          }
        }
      }
      alert(product.message);
    }

    this.setState({ saveLoading: false });
  }

	eliminar(idProducto, event) {
		event.preventDefault();
		this.setState({ eliminando: true });

		this.props.deleteProducto(idProducto, this.eliminarResponse.bind(this));
	}

	eliminarResponse(res) {
		this.setState({ eliminando: false });
		if (res.eliminado) {
		window.location = "/productos";
		} else {
		alert(res.message);
		}
	}

	handleChange(index, event) {
		const src = event.target;
		let fr = new FileReader();
		fr.onload = e => {
		const images = this.state.images;
		if(index) {
			images[index] = e.target.result;
		} else {
			document.getElementById('upload-product-image-input').src = e.target.result
			images[0] = e.target.result;
		}
		this.setState({ images });
		};
		document.getElementById("product-image").style.border = "none";
		if (src.files.length > 0) {
		fr.readAsDataURL(src.files[0]);
		}
	}

	imageError(event) {
		event.target.src =
		"https://via.placeholder.com/400?text=Click+para.actualizar+imagen";
	}

	changeStep(type) {
		// console.log(type);
		let step = document.getElementById("step" + type);
		let color = document.getElementById("colortext" + type);
		let step1 = '', color1 = '';

		for (let i = 1; i < 6; i++) {
		step1 = document.getElementById("step" + i);
		color1 = document.getElementById("colortext" + i);
		step1.classList.remove("step-active");
		step1.classList.add("step-circle");

		color1.classList.remove("color-aqua");
		color1.classList.add("color-gray");
		}

		step.classList.remove("step-circle");
		step.classList.add("step-active");

		color.classList.remove("color-gray");
		color.classList.add("color-aqua");

		this.setState({ step: type });
	}

	openPrices() {
		const modal = document.getElementById('modal-precios');
		if(modal) {
			modal.classList.remove('hidden');
		}
  }
  
  async uploadSliderImg(event, locale) {
    this.setState({slider_loading: true});

    let files = event.target.files;
		// let reader = new FileReader();
		if(files[0]) {
			const formData = new FormData();
			formData.append('files[]', files[0]);
      formData.append('locale', locale);
      formData.append('type', 'slider');
      const url = process.env.NODE_ENV === 'production' ? 'https://upload.vamoscancun.com/index.php'/*'https://upload.dwitsites.com/index.php'*/:'http://localhost:8000/uploadAPI/index.php';
      const res = await req.postToUrl(url, formData);
      //console.log(res);
			if(res.upload) {
        if (locale === 'es') {
          const esp = this.state.esp;
          Object.assign(esp, { slider: res.url });
          this.setState({ esp: esp });
        } else if (locale === 'en') {
          const ing = this.state.ing;
          Object.assign(ing, { slider: res.url });
          this.setState({ ing: ing });
        } else if (locale === 'pt') {
          const prt = this.state.prt;
          Object.assign(prt, { slider: res.url });
          this.setState({ prt: prt });
        }
				//this.setState({pdf_url: res.url});
        alert('Imagen correctamente');
			} else {
				alert('Error al cargar imagen');
				console.error(res.message);
				this.setState({ slider: undefined });
      }
      this.setState({ slider_loading: false });
		}
  }

	async uploadPDF(event, locale) {
    this.setState({ pdf_loading: true });
		let files = event.target.files;
		// let reader = new FileReader();
		if(files[0]) {
			const formData = new FormData();
			formData.append('files[]', files[0]);
			formData.append('locale', locale);
      const url = process.env.NODE_ENV === 'production' ? 'https://upload.vamoscancun.com/index.php'/*'https://upload.dwitsites.com/index.php'*/:'http://localhost:8000/uploadAPI/index.php';
			const res = await req.postToUrl(url, formData);
			if(res.upload) {
        if (locale === 'es') {
          const esp = this.state.esp;
          Object.assign(esp, { pdf: res.url });
          this.setState({ esp: esp });
        } else if (locale === 'en') {
          const ing = this.state.ing;
          Object.assign(ing, { pdf: res.url });
          this.setState({ ing: ing });
        } else if (locale === 'pt') {
          const prt = this.state.prt;
          Object.assign(prt, { pdf: res.url });
          this.setState({ prt: prt });
        }
				//this.setState({pdf_url: res.url});
        alert('Documento cargado correctamente');
			} else {
				alert('Error al cargar documento');
				console.error(res.message);
				this.setState({ pdf: undefined });
      }
      this.setState({ pdf_loading: false });
		}
	}

	optionChange(locale, event) {
		const {value} = event.target;
		const {new_option} = this.state;
		new_option[locale] = value;
		this.setState({new_option});
	}

	async addOption() {
		this.setState({loading_options: true});
		const {new_option, idProduct} = this.state;
		const res = await req.post('/products/add/option', {new_option, idProduct});
		if(res.inserted) {
			this.setState({new_option: {es: '', en: '', pt: '', personas: ''}});
			this.optionsChild.loadOptions();
		}
		this.setState({loading_options: false});
	}

	setOptions(options) {
		if(Array.isArray(options)) {
			this.setState({options});
		}
  }
  
  openModalBanner() {
    document.getElementById("modal-banner").classList.remove("hidden");
  }

  render() {
		let categories = [];
		let clasifications = [];
		let lines = [];
		let providers = [];
		if (this.state.categories) {
			categories = this.state.categories;
		}
		if (this.state.clasifications) {
			clasifications = this.state.clasifications;
		}
		if (this.props.lines) {
			lines = this.props.lines;
		}
		if (this.state.providers) {
			providers = this.state.providers;
		}
		let btn_loading;
		if (this.props.add_loadig) {
			btn_loading = <i className="fas fa-spinner fa-spin" />;
		}
    return (
      <div className="add-product justify-center">
        <div className="container container-add-product column">
          <div className="column">
            <div className="left column justify-center align-center">
              <div className="white-space-24" />
              <div className="column justify-center align-center images-map">
                <div className="product-image-preview responsive-img" onClick={() => document.getElementById("upload-product-image-input").click()}>
                  <div className="product-img justify-center align-center">
                    <img src={this.state.images[0]} alt="Click para actualizar imagen" id="product-image" onError={this.imageError.bind(this)} />
                    <div className="rd-parallax-gallery">
                      <div className="g-overlay-darker" style={{ "height": "auto" }}>
                        <div className="top-text justify-center align-center">
                          <i className="fas fa-upload"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="file" id="upload-product-image-input" onChange={this.handleChange.bind(this, false)} />
                </div>
                <div className="white-space-8" />
                <div className="product-image-four responsive-img">
                  {this.state.images.slice(1,5).map((image, index) =>
                    <div key={index} onClick={() => document.getElementById(`upload-${index}`).click()}>
                      <div className="product-img justify-start align-center">
                        <img alt="Click para actualizar imagen" src={image}/>
                        <div className="rd-parallax-gallery">
                          <div className="g-overlay-darker" style={{ "height": "auto" }}>
                            <div className="top-text justify-center align-center">
                              <i className="fas fa-upload"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input type="file" id={`upload-${index}`} style={{display: 'none'}} onChange={this.handleChange.bind(this, index + 1)} />
                    </div>
                  )}
                </div>
              </div>
              <div className="white-space-8" />
              <p className="text-center">Imagen del Producto</p>
              <div className="white-space-8" />
            </div>
            <div className="white-space-16" />
            <div className="right row justify-center">
              <div className="container">
                <form id="product-form" className="column htmlForm-add-product" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="row tabs justify-between">
                    <div className="steps align-center justify-center" onClick={this.changeStep.bind(this, 1)}>
                      <div className="step-active auto" id="step1">
                        <h5 className="weight-bold color-white">1</h5>
                      </div>
                      <div className="step-text justify-center auto">
                        <h3 className="font-small color-aqua" id="colortext1">
                          Detalles
                        </h3>
                      </div>
                    </div>
                    <div className="steps align-center justify-center" onClick={this.changeStep.bind(this, 2)}>
                      <div className="step-circle auto" id="step2">
                        <h5 className="weight-bold color-white">2</h5>
                      </div>
                      <div className="step-text justify-center auto">
                        <h3 className="font-small color-gray" id="colortext2">
                          Español
                        </h3>
                      </div>
                    </div>
                    <div className="steps align-center justify-center" onClick={this.changeStep.bind(this, 3)}>
                      <div className="step-circle auto" id="step3">
                        <h5 className="weight-bold color-white">3</h5>
                      </div>
                      <div className="step-text justify-center auto">
                        <h3 className="font-small color-gray" id="colortext3">
                          Inglés
                        </h3>
                      </div>
                    </div>
                    <div className="steps align-center justify-center" onClick={this.changeStep.bind(this, 4)}>
                      <div className="step-circle auto" id="step4">
                        <h5 className="weight-bold color-white">4</h5>
                      </div>
                      <div className="step-text justify-center auto">
                        <h3 className="font-small color-gray" id="colortext4">
                          Portugués
                        </h3>
                      </div>
                    </div>
                    <div className="steps align-center justify-center" onClick={this.changeStep.bind(this, 5)}>
                      <div className="step-circle auto" id="step5">
                        <h5 className="weight-bold color-white">5</h5>
                      </div>
                      <div className="step-text justify-center auto">
                        <h3 className="font-small color-gray" id="colortext5">
                          Precios
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="white-space-16" />
                  {this.state.step === 1 ? 
                  <div className="step1 column">
                    <div className="section-selects row-responsive justify-center">
						<div className="ss-line column">
							<label htmlFor="line">Clasificación</label>
							<div className="white-space-8" />
							<div className="price-div align-center">
							<select name="clasification" id="clasification" className="input input-select" required
									value={this.state.idClasificacion} onChange={(event) => {this.setState({ idClasificacion: event.target.value })}}>
									{/*{clasificacion ? document.getElementById(clasificacion).selected = true : null}*/}
									{/*<option value=""></option>*/}
									{clasifications.map((clasification, index) => (
									<option key={index} value={clasification.id_clasificacion}>
									{clasification.clasificacion['es'].clasificacion}
									</option>
									))}
								</select>
							</div>
						</div>
                      <div className="ss-category column">
                        <label htmlFor="category">Categoría</label>
                        <div className="white-space-8" />
                        <div className="price-div align-center">
                        <select name="category" id="category" className="input input-select" required value={this.state.idCategoria} onChange={(event) => {this.setState({ idCategoria: event.target.value })}}>
                          {/*{categoria ? document.getElementById(categoria).selected = true : null}*/}
                          {categories.map((categorie, index) => (
                          <option key={index} value={categorie.id_categoria}>
                            {categorie.categoria['es'].categoria}
                          </option>
                          ))}
                        </select>
                        </div>
                      </div>
                      <div className="ss-relevant column">
                        <label htmlFor="category">Relevante</label>
                        <div className="white-space-8" />
                        <div className="align-center justify-start">
                          <select name="relevant" id="relevant" className="input input-select" required value={this.state.relevante} onChange={(event) => { this.setState({ relevante: event.target.value }) }}>
                          <option value="0">No</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        </div>
                      </div>
                      <div className="column">
                        <label htmlFor="provider">Proveedor</label>
                        <div className="white-space-8" />
                        <div className="price-div align-center">
                          <select name="provider" id="provider" className="input input-select" required value={this.state.idProveedor} onChange={(event) => {this.setState({ idProveedor: parseInt(event.target.value) })}}>
                            {providers.map((provider, index) => (
                            <option key={index} value={provider.id_proveedor}>
                              {provider.proveedor}
                            </option>
                            ))}
                          </select>
                        </div>
						          </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="justify-center">
                      {/*<div className="column">
                        <label>Calendario Precios</label>
                        <div className="white-space-8" />
                        <div className="row align-center justify-center">
                          <div className="column align-center pointer prices-calendar" onClick={this.openPrices.bind(this)}>
                            <i className="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                      </div>*/}
						<div className="column price">
							<label htmlFor="product-price">Precio adultos</label>
							<div className="white-space-8" />
							<div className="price-div align-center">
							$ &nbsp;{" "}
							<input name="adult_price" id="adult_price" className="input input-text" required value={this.state.precio_adulto}
								onChange={(event) => {
									const price = event.target.value;
									this.setState({ precio_adulto: price });
								}}
							/>
							</div>
						</div>
						<div className="column price">
							<label htmlFor="product-price">Precio niños</label>
							<div className="white-space-8" />
							<div className="price-div align-center">
							$ &nbsp;{" "}
								<input name="children_price" id="children_price" className="input input-text" required
									value={this.state.precio_menor}
									onChange={(event) => {
									const price = event.target.value;
									this.setState({ precio_menor: price });
									}}
								/>
							</div>
						</div>
                      <div className="column price">
                        <label htmlFor="product-price">Promoción</label>
                        <div className="white-space-8" />
                        <div className="price-div align-center">
                        % &nbsp;{" "}
                        <input name="product_promotion" id="product_promotion" className="input input-text" required value={this.state.promocion || 0}
                          onChange={(event) => {
                          const promotion = event.target.value;
                          this.setState({ promocion: promotion });
                          }}
                        />
                        </div>
                      </div>
                      <div className="column price">
                        <label htmlFor="product-price">Descuento agente</label>
                        <div className="white-space-8" />
                        <div className="price-div align-center">
                          % &nbsp;{" "}
                          <input name="product_descount" id="product_descount" className="input input-text" required value={this.state.descuento || 0}
                            onChange={(event) => {
                              const descount = event.target.value;
                              this.setState({ descuento: descount });
                            }}
                          />
                        </div>
                      </div>
                      <div className="limit column justify-center align-center">
                        <label htmlFor="active">Agencia</label>
                        <div className="white-space-8" />
                        <div className="align-center justify-center">
                          <input type="checkbox" name="product_existence" id="product_existence" className="input input-checkbox" checked={this.state.agenciaCheck ? true : false}
                            onChange={(event) => this.setState({ agenciaCheck: event.target.checked })}
                          />
                        </div>
                      </div>
                      <div className="limit column justify-center align-center">
                        <label htmlFor="active">Activo</label>
                        <div className="white-space-8" />
                        <div className="align-center justify-center">
                          <input type="checkbox" name="product_existence" id="product_existence" className="input input-checkbox" checked={this.state.activo ? true : false}
                            onChange={(event) => this.setState({ activo: event.target.checked })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="row">
                        <div className="space column">
                        <label>Edad adulto</label>
                        <div className="white-space-8"></div>
                        <input className="input input-text" type="text" id="adult_age" name="adult_age" value={this.state.adult_age} onChange={(event) => {
                          const adult_age = event.target.value;
                          this.setState({ adult_age });
                        }} />
                      </div>
                      <div className="space column">
                        <label>Edad niños</label>
                        <div className="white-space-8"></div>
                        <input className="input input-text" type="text" id="chil_age" name="child_age" value={this.state.child_age} onChange={(event) => {
                          const child_age = event.target.value;
                          this.setState({ child_age });
                        }} />
                      </div>
                      <div className="column">
                        <label htmlFor="limit">Límite de días de reserva</label>
                        <div className="white-space-8" />
                        <input type="text" name="product_limit" id="product_limit" className="input input-text" value={this.state.limit || ''}
                          onChange={(event) => {
                            const limit = event.target.value;
                            this.setState({ limit: limit });
                          }}
                        />
                      </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div>
                      <div className="link column">
                        <label htmlFor="link">Link Video</label>
                        <div className="white-space-8" />
                        <input type="text" name="product_link" id="product_link" className="input input-text" value={this.state.link || ''}
                          onChange={(event) => {
                            const link = event.target.value;
                            this.setState({ link: link });
                          }} />
                      </div>
                    </div>
                  </div>
                  : this.state.step === 2 ? 
                  <div className="step2 column">
                    <label htmlFor="product_name">Título(ESP)</label>
                    <div className="white-space-8" />
                    <input
                      type="text"
                      name="product_name"
                      id="product_name"
                      className="input input-text"
                      value={this.state.esp.titulo || ''}
                      onChange={(event) => {
                        const esp = this.state.esp;
                        Object.assign(esp, { titulo: event.target.value })
                        this.setState({ esp: esp })
                      }}
                      required
                    />
                    <div className="white-space-16" />
                    <label htmlFor="product_description">Descripción corta(ESP)</label>
                    <div className="white-space-8" />
                    <input
                      type="text"
                      name="product_description_short"
                      id="product_description_short"
                      className="input input-text"
                      value={this.state.esp.descripcion_corta || ''}
                      required
                      onChange={(event) => {
                        const esp = this.state.esp;
                        Object.assign(esp, { descripcion_corta: event.target.value })
                        this.setState({ esp })
                      }}
                    />
                    <div className="white-space-16" />
                    <label htmlFor="product_description">Descripción(ESP)</label>
                    <div className="white-space-8" />
                    <textarea
                      name="product_description"
                      id="product_description"
                      rows="8"
                      className="input textarea"
                      /*maxLength="800"*/
                      value={this.state.esp.descripcion || ''}
                      onChange={(event) => {
                        const esp = this.state.esp;
                        Object.assign(esp, { descripcion: event.target.value })
                        this.setState({ esp: esp })
                      }}
                    />
                    <div className="white-space-16" />
                    <div className="row">
                      <div className="column">
                        <label htmlFor="product_description">Incluye (ESP) <small>separar con un <b>;</b></small></label>
                        <div className="white-space-8" />
                        <textarea
                          name="product_description"
                          id="product_description"
                          rows="10"
                          className="input textarea"
                          /*maxLength="800"*/
                          value={this.state.esp.incluye || ''}
                          onChange={(event) => {
                            const esp = this.state.esp;
                            Object.assign(esp, { incluye: event.target.value })
                            this.setState({ esp: esp })
                          }}
                        />
                      </div>
                      <div className="column">
                        <label htmlFor="product_description">NO Incluye (ESP) <small>separar con un <b>;</b></small></label>
                        <div className="white-space-8" />
                        <textarea
                          name="product_description"
                          id="product_description"
                          rows="10"
                          className="input textarea"
                          /*maxLength="800"*/
                          value={this.state.esp.noincluye || ''}
                          onChange={(event) => {
                            const esp = this.state.esp;
                            Object.assign(esp, { noincluye: event.target.value })
                            this.setState({ esp: esp })
                          }}
                        />
                      </div>
                    </div>
                    <div className="white-space-16" />
                      <label htmlFor="product_description">Recomendaciones(ESP)  <small>separar con un <b>;</b></small></label>
                    <div className="white-space-8" />
                    <textarea
                      name="product_description"
                      id="product_description"
                      rows="5"
                      className="input textarea"
                      /*maxLength="800"*/
                      value={this.state.esp.recomendaciones || ''}
                      onChange={(event) => {
                        const esp = this.state.esp;
                        Object.assign(esp, { recomendaciones: event.target.value })
                        this.setState({ esp: esp })
                      }}
                    />
                    <div className="white-space-16" />
                    <div className="row">
                      <div className="column">
                        <label htmlFor="product_pdf">PDF Tour</label>
                        <div className="white-space-8" />
                        <div className="row">
                          {this.state.pdf_loading ?
                            <div className="file">
                              <label className="pointer btn btn-red" htmlFor="product_pdf_es">
                                <i className="fas fa-spinner fa-spin"></i>
                              </label>
                            </div>
                            :
                            <div className="file">
                              <label className="pointer btn btn-red" htmlFor="product_pdf_es">
                                Subir PDF &nbsp;<i className="fas fa-upload"></i>
                              </label>
                              <input type="file" name="product_pdf_es" id="product_pdf_es" className="hidden" accept="application/pdf" value={this.state.pdf || ''}
                                onChange={(event) => this.uploadPDF(event, 'es')} />
                            </div>}
                          {this.state.esp.pdf &&
                            <React.Fragment>
                              <div className="pdf align-center">
                                <a className="font-tiny color-o-red" target="_blank" href={this.state.esp.pdf}>
                                  <i class="far fa-file-pdf"></i> Ver PDF de tour
                                </a>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                      <div className="column">
                        <label htmlFor="product_pdf">Imagen de slider</label>
                        <div className="white-space-8" />
                        <div className="row">
                          <div className="file-slider">
                            <label className="pointer btn btn-yellow" htmlFor="slider_img_es">
                              Subir imagen &nbsp;<i className="fas fa-upload"></i>
                            </label>
                                <input type="file" name="slider_img_es" id="slider_img_es" className="hidden" accept="image/*" value={this.state.slider || ''} onChange={(event) => this.uploadSliderImg(event, 'es')} />
                          </div>
                          {this.state.esp.slider &&
                            <React.Fragment>
                              <div className="pdf align-center">
                                <a className="font-tiny color-o-red" /*onClick={this.openModalBanner.bind(this)}*/ target="_blank" href={this.state.esp.slider}>
                                  <i class="far fa-image"></i> Ver imagen
                                </a>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  : this.state.step === 3 ?
                  <div className="step3 column">
                    <label htmlFor="product_name_ing">
                      Titulo(ING)
                    </label>
                    <div className="white-space-8" />
                    <input
                      type="text"
                      name="product_name_ing"
                      id="product_name_ing"
                      className="input input-text"
                      value={this.state.ing.titulo || ''}
                      required
                      onChange={(event) => {
                        const ing = this.state.ing;
                        Object.assign(ing, { titulo: event.target.value })
                        this.setState({ ing: ing })
                      }}
                    />
                    <div className="white-space-16" />
                    <label htmlFor="product_description">Descripción corta(ING)</label>
                    <div className="white-space-8" />
                    <input
                      type="text"
                      name="product_description_short"
                      id="product_description_short"
                      className="input input-text"
                      value={this.state.ing.descripcion_corta || ''}
                      required
                      onChange={(event) => {
                        const ing = this.state.ing;
                        Object.assign(ing, { descripcion_corta: event.target.value })
                        this.setState({ ing })
                      }}
                    />
                    <div className="white-space-16" />
                    <label htmlFor="product_description_ing">
                      Descripción(ING)
                    </label>
                    <div className="white-space-8" />
                    <textarea
                      name="product_description_ing"
                      id="product_description_ing"
                      rows="8"
                      className="input textarea"
                      /*maxLength="800"*/
                      value={this.state.ing.descripcion || ''}
                      onChange={(event) => {
                        const ing = this.state.ing;
                        Object.assign(ing, { descripcion: event.target.value })
                        this.setState({ ing: ing })
                      }}
                    />
                    <div className="white-space-16" />
                    <div className="row">
                      <div className="column">
                        <label htmlFor="product_description">Incluye (ING) <small>separar con un <b>;</b></small></label>
                        <div className="white-space-8" />
                        <textarea
                          name="product_description"
                          id="product_description"
                          rows="10"
                          className="input textarea"
                          /*maxLength="800"*/
                          value={this.state.ing.incluye || ''}
                          onChange={(event) => {
                            const ing = this.state.ing;
                            Object.assign(ing, { incluye: event.target.value })
                            this.setState({ ing: ing })
                          }}
                        />
                      </div>
                      <div className="column">
                        <label htmlFor="product_description">NO Incluye (ING) <small>separar con un <b>;</b></small></label>
                        <div className="white-space-8" />
                        <textarea
                          name="product_description"
                          id="product_description"
                          rows="10"
                          className="input textarea"
                          /*maxLength="800"*/
                          value={this.state.ing.noincluye || ''}
                          onChange={(event) => {
                            const ing = this.state.ing;
                            Object.assign(ing, { noincluye: event.target.value })
                            this.setState({ ing: ing })
                          }}
                        />
                      </div>
                    </div>
                    <div className="white-space-16" />
                    <label htmlFor="product_description">Recomendaciones(ING)</label>
                    <div className="white-space-8" />
                    <textarea
                      name="product_description"
                      id="product_description"
                      rows="5"
                      className="input textarea"
                      /*maxLength="800"*/
                      value={this.state.ing.recomendaciones || ''}
                      onChange={(event) => {
                        const ing = this.state.ing;
                        Object.assign(ing, { recomendaciones: event.target.value })
                        this.setState({ ing: ing })
                      }}
                    />
                    <div className="white-space-16" />
                    <div className="row">
                      <div className="column">
                        <label htmlFor="product_pdf">PDF Tour</label>
                        <div className="white-space-8" />
                        <div className="row">
                          {this.state.pdf_loading ?
                            <div className="file">
                              <label className="pointer btn btn-red" htmlFor="product_pdf_en">
                                <i className="fas fa-spinner fa-spin"></i>
                              </label>
                            </div>
                            :
                            <div className="file">
                              <label className="pointer btn btn-red" htmlFor="product_pdf_en">
                                Subir PDF &nbsp;<i className="fas fa-upload"></i>
                              </label>
                              <input type="file" name="product_pdf_en" id="product_pdf_en" className="hidden" accept="application/pdf" value={this.state.pdf || ''}
                                onChange={(event) => this.uploadPDF(event, 'en')} />
                            </div>}
                          {this.state.ing.pdf &&
                            <React.Fragment>
                              <div className="pdf align-center">
                                <a className="font-tiny color-o-red" target="_blank" href={this.state.ing.pdf}>
                                  <i class="far fa-file-pdf"></i> Ver PDF de tour
                                </a>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                      <div className="column">
                        <label htmlFor="product_pdf">Imagen de slider</label>
                        <div className="white-space-8" />
                        <div className="row">
                          <div className="file-slider">
                            <label className="pointer btn btn-yellow" htmlFor="slider_img_en">
                              Subir imagen &nbsp;<i className="fas fa-upload"></i>
                            </label>
                            <input type="file" name="slider_img_en" id="slider_img_en" className="hidden" accept="image/*" value={this.state.slider || ''} onChange={(event) => this.uploadSliderImg(event, 'en')} />
                          </div>
                          {this.state.ing.slider &&
                            <React.Fragment>
                              <div className="pdf align-center">
                                <a className="font-tiny color-o-red" target="_blank" href={this.state.ing.slider}>
                                  <i class="far fa-image"></i> Ver imagen
                                </a>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  : this.state.step === 4 ? 
                  <div className="step4 column">
                    <label htmlFor="product_name_ing">
                      Titulo(PRT)
                    </label>
                    <div className="white-space-8" />
                    <input
                      type="text"
                      name="product_name_ing"
                      id="product_name_ing"
                      className="input input-text"
                      value={this.state.prt.titulo || ''}
                      required
                      onChange={(event) => {
                        const prt = this.state.prt;
                        Object.assign(prt, { titulo: event.target.value })
                        this.setState({ prt })
                      }}
                    />
                    <div className="white-space-16" />
                    <label htmlFor="product_description">Descripción corta(PRT)</label>
                    <div className="white-space-8" />
                    <input
                      type="text"
                      name="product_description_short"
                      id="product_description_short"
                      className="input input-text"
                      value={this.state.prt.descripcion_corta || ''}
                      required
                      onChange={(event) => {
                        const prt = this.state.prt;
                        Object.assign(prt, { descripcion_corta: event.target.value })
                        this.setState({ prt })
                      }}
                    />
                    <div className="white-space-16" />
                    <label htmlFor="product_description_ing">
                      Descripción(PRT)
                    </label>
                    <div className="white-space-8" />
                    <textarea
                      name="product_description_ing"
                      id="product_description_ing"
                      rows="8"
                      className="input textarea"
                      /*maxLength="800"*/
                      value={this.state.prt.descripcion || ''}
                      onChange={(event) => {
                        const prt = this.state.prt;
                        Object.assign(prt, { descripcion: event.target.value })
                        this.setState({ prt })
                      }}
                    />
                    <div className="white-space-16" />
                    <div className="row">
                      <div className="column">
                        <label htmlFor="product_description">Incluye (PRT) <small>separar con un <b>;</b></small></label>
                        <div className="white-space-8" />
                        <textarea
                          name="product_description"
                          id="product_description"
                          rows="10"
                          className="input textarea"
                          /*maxLength="800"*/
                          value={this.state.prt.incluye || ''}
                          onChange={(event) => {
                            const prt = this.state.prt;
                            Object.assign(prt, { incluye: event.target.value })
                            this.setState({ prt: prt })
                          }}
                        />
                      </div>
                      <div className="column">
                        <label htmlFor="product_description">NO Incluye (PRT) <small>separar con un <b>;</b></small></label>
                        <div className="white-space-8" />
                        <textarea
                          name="product_description"
                          id="product_description"
                          rows="10"
                          className="input textarea"
                          /*maxLength="800"*/
                          value={this.state.prt.noincluye || ''}
                          onChange={(event) => {
                            const prt = this.state.prt;
                            Object.assign(prt, { noincluye: event.target.value })
                            this.setState({ prt: prt })
                          }}
                        />
                      </div>
                    </div>
                    <div className="white-space-16" />
                    <label htmlFor="product_description">Recomendaciones(PRT)</label>
                    <div className="white-space-8" />
                    <textarea
                      name="product_description"
                      id="product_description"
                      rows="5"
                      className="input textarea"
                      /*maxLength="800"*/
                      value={this.state.prt.recomendaciones || ''}
                      onChange={(event) => {
                        const prt = this.state.prt;
                        Object.assign(prt, { recomendaciones: event.target.value })
                        this.setState({ prt: prt })
                      }}
                      
                    />
                    <div className="white-space-16"></div>
                    <div className="row">
                      <div className="column">
                        <label htmlFor="product_pdf">PDF Tour</label>
                        <div className="white-space-8" />
                        <div className="row">
                          {this.state.pdf_loading ?
                            <div className="file">
                              <label className="pointer btn btn-red" htmlFor="product_pdf_pt">
                                <i className="fas fa-spinner fa-spin"></i>
                              </label>
                            </div>
                            :
                            <div className="file">
                              <label className="pointer btn btn-red color-white" htmlFor="product_pdf_pt">
                                Subir PDF &nbsp;<i className="fas fa-upload"></i>
                              </label>
                              <input type="file" name="product_pdf_pt" id="product_pdf_pt" className="hidden" accept="application/pdf" value={this.state.pdf || ''}
                                onChange={(event) => this.uploadPDF(event, 'pt')} />
                            </div>}
                          {this.state.prt.pdf &&
                            <React.Fragment>
                              <div className="pdf align-center">
                                <a className="font-tiny color-o-red" target="_blank" href={this.state.prt.pdf}>
                                  <i class="far fa-file-pdf"></i> Ver PDF de tour
                                </a>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                      <div className="column">
                        <label htmlFor="product_pdf">Imagen de slider</label>
                        <div className="white-space-8" />
                        <div className="row">
                          <div className="file-slider">
                            <label className="pointer btn btn-yellow" htmlFor="slider_img_pt">
                              Subir imagen &nbsp;<i className="fas fa-upload"></i>
                            </label>
                            <input type="file" name="slider_img_pt" id="slider_img_pt" className="hidden" accept="image/*" value={this.state.slider || ''} onChange={(event) => this.uploadSliderImg(event, 'pt')} />
                          </div>
                          {this.state.prt.slider &&
                            <React.Fragment>
                              <div className="pdf align-center">
                                <a className="font-tiny color-o-red" target="_blank" href={this.state.prt.slider}>
                                  <i class="far fa-image"></i> Ver imagen
                                </a>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  : this.state.step === 5 ?  
                  <div className="column">
                    <div  className="column align-center justify-center">
                      <div style={{ "width": '90%' }}>
                        <h4 className="color-black">Opciones</h4>
                      </div>
                      <div className="white-space-16"></div>
                      <div className="column options align-center justify-center">
                        <div className="white-space-24"></div>
                        <form className="add-options justify-center align-center" onSubmit={this.addOption.bind(this)}>
                          <div className="column">
                            Español:
                            <input className="input input-text" type="text" value={this.state.new_option.es} onChange={this.optionChange.bind(this, 'es')} required/>
                          </div>
                          <div className="column">
                            Inglés:<input className="input input-text" type="text" value={this.state.new_option.en} onChange={this.optionChange.bind(this, 'en')} required/>
                          </div>
                          <div className="column">
                            Portugués:<input className="input input-text" type="text" value={this.state.new_option.pt} onChange={this.optionChange.bind(this, 'pt')} required/>
                          </div>
						  {
							this.state.idCategoria == 31 && 
							<div className="column">
								Pax:<input className="input input-text" type="text" value={this.state.new_option.personas} onChange={this.optionChange.bind(this, 'personas')} required/>
							</div>
						  }
                          &nbsp;
                          {this.state.loading_options? 
                            <i className="fas fa-spinner fa-spin"></i>
                            :
                            <div className="row justify-end">
                              <button type="submit" className="btn btn-yellow">
                                <i className="fas fa-plus color-white"></i> Agregar
                              </button>
                            </div>
                            }
                        </form>
                        <div className="white-space-16"></div>
                        <div>
                          <Options ref={instance => { this.optionsChild = instance; }} idProduct={this.state.idProduct} setOptions={this.setOptions.bind(this)} idCategoria={this.state.idCategoria}/>
                        </div>
                        <div className="white-space-24"></div>
                      </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="column align-center justify-center">
                      <div style={{"width": '90%'}}>
                        <h4 className="color-black">
                          Calendario
                        </h4>
                      </div>
                      <div className="white-space-16"></div>
                      <Precios id_producto={this.state.idProduct} options={this.state.options}/>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="column align-center justify-center">
                      <div style={{ "width": '90%' }}>
                        <h4 className="color-black">
                          Bloqueo de fechas
                        </h4>
                      </div>
                      <div className="white-space-16"></div>
                      <div className="bloqueo column justify-center align-center">
                        <div className="container column justify-center align-center">
                          <div className="white-space-24"></div>
                          <div className="column" style={{ "width": '90%' }}>
                            <p className="color-black">- Si requiere más de una fecha para bloquear, utilice ; como separación, por ejemplo 2020-1-03; 2020-3-31.</p>
                            <p className="color-black">- Si se quiere bloquear un día en general del calendario, se utilizará la inicial correspondiente.</p>
                            <p className="color-black">(L=Lunes, MT=Martes, M=Miércoles, J=Jueves, V=Viernes, S=Sábado, D=Domingo).</p>
                            <p className="color-black">- Si se quiere bloquear un mes, escribir el nombre del mes completo, p.e.: Marzo.</p>
                          </div>
                          <div className="white-space-24"></div>
                          <div className="justify-center">
                            <textarea className="input" name="cancel" id="cancel" cols="90" rows="5" placeholder="Ejemplo: 2020-01-20;D;Marzo" value={this.state.fechas_bloq || ''}
                            onChange={(event) => {
                              this.setState({ fechas_bloq: event.target.value })
                            }}></textarea> 
                          </div>
                          <div className="white-space-24"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}
                  <div className="white-space-24" />
                  <div className={`btn-container-publish ${this.state.step === 1? 'justify-end': 'justify-end'}`}>
                    {/*this.state.step !== 1?
                      <button className="btn btn-aqua" type="button">
                        Anterior
                      </button>
                      : null
                    */}
                    {this.state.step !== 5 ?
                    <React.Fragment>
                      <button className="btn btn-aqua" type="button" onClick={this.changeStep.bind(this, (this.state.step + 1))}>
                        Siguiente
                      </button> &nbsp;
                      {this.state.saveLoading ?
                        <div className="justify-center align-center" style={{width: "10%"}}>
                          <i className="fas fa-spinner fa-spin"></i>
                        </div>
                      :
                        <button className="btn btn-green" type="button" onClick={this.handleSubmit.bind(this)}>
                        Finalizar
                        </button>}
                    </React.Fragment> :
                      this.state.saveLoading ?
                          <i className="fas fa-spinner fa-spin"></i>
                          :
                          <button className="btn btn-green" type="button" onClick={this.handleSubmit.bind(this)}>
                            Finalizar
                        </button>
                    }
                    {/*{this.state.step !== 5 ?
                      <button className="btn btn-aqua" type="button" onClick={this.changeStep.bind(this, (this.state.step + 1))}>
                        Siguiente
                      </button>
					            :
                      this.state.saveLoading?
                        <i className="fas fa-spinner fa-spin"></i>
                        :
                        <button className="btn btn-aqua" type="button" onClick={this.handleSubmit.bind(this)}>
                          Finalizar
                        </button>
                    }*/}
                  </div>
                  <div className="white-space-24" />
                  <div>
                    <label>{this.props.add_message}</label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalSliderVP image={this.state.esp.slider} />
        {/*<Precios id_producto={this.state.idProduct}/>*/}
      </div>
    );
  }
}

export default DetailProduct;
