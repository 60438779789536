import React, { Component } from 'react';

class AgenciasRow extends Component {
    render() {
        let agencia = this.props.agencia ? this.props.agencia : [];
        return(
            <tr className="table-row" id="open-modal-order">
                <td>{agencia.id_agencia}</td>
                <td>{agencia.agencia}</td>
                <td className="edit">
                    <button className="btn" onClick={this.props.modalAddAgencia.bind(this, 2, agencia)}>
                        <i className="fas fa-pencil-alt" />
                    </button>
                </td>
                <td className="delete">
                    <button className="btn" onClick={() => {
                        var modal = document.getElementById('modal_delete_agencia');
                        if (modal) {
                            modal.classList.remove("hidden");
                            this.props.deleteAgencia(agencia.id_agencia);
                        }
                    }}>
                        <i className="fas fa-trash" />
                    </button>
                </td>
            </tr>
        )
    }
}

export default AgenciasRow;