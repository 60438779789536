import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import Request from '../core/httpClient';

// Web Components
import Aside from '../components/aside/aside';
import Filters from '../components/products/filters';
import ProductsTable from '../components/products/products-table';
import Paginator from '../components/paginator/paginator';
import Header from "../components/header/header";

const req = new Request();

class Productos extends Component {
    constructor(props) {
        super(props);
        this.state={
            page:1,
            products: [],
            search: ''
        }
    }

    componentDidMount() {
        this.load({busqueda: ''});
    }

    async load(params) {
        this.setState({loading: true});
        const data = {
            search: params.busqueda
        };
        const res = await req.post('/admin/products/buscar', data);
        this.setState({message: res.message});
        if(res.products) {
            this.setState({products: res.products});
        }
        this.setState({loading: false});
    }

    search(data) {
        this.load(data);
    }

    setpage(page) {
        this.setState({page: page})
    }
    render() {
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32"></div>
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">
                                            TODOS LOS PRODUCTOS
                                        </h3>
                                    </div>
                                    <div className="justify-end">
                                        <Link to="productos/agregar" className="btn btn-green" id="open-modal-add-user">
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR PRODUCTO
                                        </Link>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <h5 className="color-black">
                                    FILTRAR PRODUCTOS
                                </h5>
                                <div className="white-space-8"></div>
                                <Filters search={this.search.bind(this)} />
                                <div className="white-space-16"></div>
                                <ProductsTable page={this.state.page} products={this.state.products} loading={this.state.loading} />
                                <div className="row justify-center">
                                    {this.state.message}
                                </div>
                                <div className="white-space-32"></div>
                                {this.state.products.length > 0 ?
                                    <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.state.products.length / 10)} /> : null}
                                <div className="white-space-32"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Consumer(Productos);
