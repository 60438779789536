import React, { Component } from "react";
import Request from '../core/httpClient';

// Web Components
import Aside from "../components/aside/aside";
import DetailProduct from "../components/products/detail";
import Header from "../components/header/header";

const req = new Request();

class DetalleProducto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationStatus: "none",
      esp: {titulo:'', descripcion: ''},
      ing: {titulo:'', descripcion: ''},
      prt: {titulo:'', descripcion: ''},
      product: {}
    };
  }

  componentDidMount() {
    this.load()
  }

  async load() {
    this.setState({loading: true});
    if(this.props.match) {
      const params = this.props.match.params;
      if(params.id) {
        const data = {
          id_producto: params.id
        }
        const res = await req.post('/admin/products/detail', data);
        this.setState({message: res.message});
        if(res.product) {
          const product = res.product;
          const esp = {}, ing = {}, prt = {};
          // Titulos
          if(product.titulo) {
            product.titulo = JSON.parse(product.titulo);
            esp.titulo = product.titulo['es'];
            ing.titulo = product.titulo['en'];
            prt.titulo = product.titulo['pt'];
          }
          // Descripciones
          if(product.descripcion) {
            product.descripcion = JSON.parse(product.descripcion);
            esp.descripcion = product.descripcion['es'];
            ing.descripcion = product.descripcion['en'];
            prt.descripcion = product.descripcion['pt'];
          }
          // Descripciones cortas
          if (product.descripcion_corta) {
            product.descripcion_corta = JSON.parse(product.descripcion_corta);
            esp.descripcion_corta = product.descripcion_corta['es'];
            ing.descripcion_corta = product.descripcion_corta['en'];
            prt.descripcion_corta = product.descripcion_corta['pt'];
          }
          // Incluye
          if (product.incluye) {
            product.incluye = JSON.parse(product.incluye);
            esp.incluye = product.incluye['es'];
            ing.incluye = product.incluye['en'];
            prt.incluye = product.incluye['pt'];
          }
          // Descripciones cortas
          if (product.noincluye) {
            product.noincluye = JSON.parse(product.noincluye);
            esp.noincluye = product.noincluye['es'];
            ing.noincluye = product.noincluye['en'];
            prt.noincluye = product.noincluye['pt'];
          }
          // Recomendaciones
          if (product.recomendaciones) {
            product.recomendaciones = JSON.parse(product.recomendaciones);
            esp.recomendaciones = product.recomendaciones['es'];
            ing.recomendaciones = product.recomendaciones['en'];
            prt.recomendaciones = product.recomendaciones['pt'];
          }

          //Pdfs
          console.log(product.pdf);
          if(product.pdf) {
            product.pdf = JSON.parse(product.pdf);
            esp.pdf = product.pdf['es'];
            ing.pdf = product.pdf['en'];
            prt.pdf = product.pdf['pt'];
          }

          console.log(product.slider);
          if(product.slider) {
            product.slider = JSON.parse(product.slider);
            esp.slider = product.slider['es'];
            ing.slider = product.slider['en'];
            prt.slider = product.slider['pt'];
          }

          console.log(product.fechas_bloqueadas);
          if (product.fechas_bloqueadas) {
            let stringBloq = '';
            product.fechas_bloqueadas = JSON.parse(product.fechas_bloqueadas);
            if (product.fechas_bloqueadas.fechas) {
              const arrayFechas = product.fechas_bloqueadas.fechas.split(',');

              for (let i = 0; i < arrayFechas.length; i++) {
                if (i > 0) {
                  stringBloq = stringBloq + ';';
                }
                stringBloq = stringBloq + arrayFechas[i];
              }
            }
            if (product.fechas_bloqueadas.dias) {
              const arrayDias = product.fechas_bloqueadas.dias.split(',');

              for (let x = 0; x < arrayDias.length; x++) {
                if (stringBloq !== '') {
                  stringBloq = stringBloq + ';';
                }

                switch (arrayDias[x]) {
                  case '0':
                    stringBloq = stringBloq + 'D'
                    break;
                  case '1':
                    stringBloq = stringBloq + 'L'
                    break;
                  case '2':
                    stringBloq = stringBloq + 'MT'
                    break;
                  case '3':
                    stringBloq = stringBloq + 'M'
                    break;
                  case '4':
                    stringBloq = stringBloq + 'J'
                    break;
                  case '5':
                    stringBloq = stringBloq + 'V'
                    break;
                  case '6':
                    stringBloq = stringBloq + 'S'
                    break;
                  default:
                    break;
                }
              }
            }
            if (product.fechas_bloqueadas.meses) {
              const arrayMeses = product.fechas_bloqueadas.meses.split(',');

              for (let y = 0; y < arrayMeses.length; y++) {
                if (stringBloq !== '') {
                  stringBloq = stringBloq + ';';
                }
                switch (arrayMeses[y]) {
                  case '0':
                    stringBloq = stringBloq + 'Enero';
                    break;
                  case '1':
                    stringBloq = stringBloq + 'Febrero';
                    break;
                  case '2':
                    stringBloq = stringBloq + 'Marzo';
                    break;
                  case '3':
                    stringBloq = stringBloq + 'Abril';
                    break;
                  case '4':
                    stringBloq = stringBloq + 'Mayo';
                    break;
                  case '5':
                    stringBloq = stringBloq + 'Junio';
                    break;
                  case '6':
                    stringBloq = stringBloq + 'Julio';
                    break;
                  case '7':
                    stringBloq = stringBloq + 'Agosto';
                    break;
                  case '8':
                    stringBloq = stringBloq + 'Septiembre';
                    break;
                  case '9':
                    stringBloq = stringBloq + 'Octubre';
                    break;
                  case '10':
                    stringBloq = stringBloq + 'Noviembre';
                    break;
                  case '11':
                    stringBloq = stringBloq + 'Diciembre';
                    break;
                  default:
                    break;
                }
              }
            }
            console.log(stringBloq);
            product.fechas_bloqueadas = stringBloq;
          }

          this.setState({esp, ing, prt, product});
        }
        this.setState({ locationStatus: "modificar" });
      } else {
        this.setState({ locationStatus: "agregar", nuevo: true });
      }
    }
    this.setState({loading: false});
  }

  success(response) {
    // console.log(response);
    const esp = response.find(product => product.idioma === "es");
    const ing = response.find(product => product.idioma === "en");
    this.setState({
      esp: esp || {},
      ing: ing || {}
    });
  }

  saveResponse(response) {
    if(response.length > 0) {
      window.location = '/productos';
    }
  }

  saveProductSuccess() {

  }

  saveProduct(data) {
    if (this.state.locationStatus === "agregar") {
    } else if (this.state.locationStatus === "modificar") {

    }
  }
  deleteProducto(idProducto, response) {
  }

  render() {
    return (
      <div className="workspace">
        <div className="column">
          <Header />
          <div>
            <Aside />
            <div className="control-content justify-center">
              <div className="container column">
                <div className="white-space-32" />
                <div className="align-center justify-center">
                  <div className="container">
                    <h3 className="color-black">
                      {this.state.locationStatus === "agregar"
                        ? "AGREGAR PRODUCTO"
                        : "MODIFICAR PRODUCTO"}
                      {this.props.loading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : null}
                    </h3>
                  </div>
                </div>
                <div className="white-space-16" />
                <DetailProduct
                  product={this.state.product}
                  productStatus={this.state.locationStatus}
                  esp={this.state.esp}
                  ing={this.state.ing}
                  prt={this.state.prt}
                  lines={this.props.lines}
                  saveProduct={this.saveProduct.bind(this)}
                  add_loadig={this.props.add_loadig}
                  add_message={this.props.add_message}
                  deleteProducto={this.deleteProducto.bind(this)}
                />
                <div className="white-space-32" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetalleProducto;

