import React, { Component } from "react";
import { Link } from 'react-router-dom';

class ProveedorRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <tr className="table-row" id="open-modal-order">
                <td>{this.props.id_proveedor}</td>
                <td>{this.props.proveedor}</td>
                <td>{this.props.telefono}</td>
                <td>{this.props.email}</td>
                {/*<td><input type="checkbox" checked={this.props.id_status === 1 ? true : false} disabled /></td>*/}
                <td className="edit">
                    <button className="btn" onClick={this.props.modalAddProveedor.bind(this, this.props.id_proveedor, this.props)}>
                        <i className="fas fa-pencil-alt" />
                    </button>
                </td>
                <td className="costs">
                    <Link className="btn" to={`/costos/${this.props.id_proveedor}`}>
                        <i class="fas fa-coins"></i>
                    </Link>
                </td>
                <td className="delete">
                    <button className="btn" onClick={() => {
                        var modal = document.getElementById('modal_delete_category');
                        if (modal) {
                            modal.classList.remove("hidden");
                            this.props.deleteProvider();
                        }
                    }}/*onClick={this.deleteTour.bind(this)}*/>
                        <i className="fas fa-trash" />
                    </button>
                </td>
            </tr>
        );
    }
};

export default ProveedorRow;