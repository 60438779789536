import React, { Component } from "react";
import dayjs from "dayjs";

// Web Components
import SummaryRow from "./summary-row";

class SummaryOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
    return (
      <div className="column">
        <div className="white-space-32" />
        <h3 className="color-black">ÚLTIMAS RESERVACIONES</h3>
        <div className="white-space-16" />
        <div className="white-space-16" />
        <div className="summary-orders-table">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th> TOUR </th>
                  <th> FECHA </th>
                  <th> IMPORTE </th>
                </tr>
              </thead>
              <tbody>
                {this.props.loading ? (
                  <tr>
                    <td colSpan="3">
                      <i className="fas fa-spinner fa-spin" />
                    </td>
                  </tr>
                ) : (
                  this.props.reservations.map((row, index) => <SummaryRow key={index} row={row} />)
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryOrders;

