import React, { Component } from "react";
import Request from "../../core/httpClient";
const req = new Request();

class AddProveedor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estado: 1
        };
    }

    close() {
        const modal = document.getElementById("modal-add-proveedor");
        modal.style.display = "none";
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({
                estado: this.props.provider.id_status
            });
        }
    }

    async handleSubmit(edit, event) {
        event.preventDefault();
        this.setState({loading: true, message: ''});
        const form = event.target;
        const data = {
            nombre: form.name.value,
            telefono: form.phone.value,
            email: form.email.value,
            //id_status: this.state.estado,
            id_proveedor: this.props.provider.id_proveedor
        }
        if(edit) {
            const res = await req.post('/admin/providers/edit', data);
            if(res.updated) {
                window.location.reload();
            }
            this.setState({loading: false, message: res.message});             
        } else {
            const res = await req.post('/admin/providers/add', data);
            if(res.inserted) {
                window.location.reload();
            }
            this.setState({loading: false, message: res.message});
        }
    }

    render() {
        let edit = false;
        if(this.props.provider) {
            console.log(this.props.provider);
            edit = this.props.provider.id_proveedor? true:false;
            const name = document.getElementById('provider-name');
            if(name) {
                name.value = this.props.provider.proveedor;
            }
            const phone = document.getElementById('provider-phone');
            if(phone) {
                phone.value = this.props.provider.telefono;
            }
            const email = document.getElementById('provider-email');
            if(email) {
                email.value = this.props.provider.email;
            }
        }
        return (
            <div className="modal column justify-center align-center faster hidden" id="modal-add-proveedor">
                <form className="container modal-content align-center column" onSubmit={this.handleSubmit.bind(this, edit)}>
                    <div className="column modal-head">
                        <div className="white-space-16"></div>
                        <h3 className="text-center">
                            {edit? 'EDITAR PROVEEDOR':'AGREGAR PROVEEDOR'}
                        </h3>
                        <div className="white-space-16"></div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="provider-data justify-center">
                        <div className="container">
                            <div className="provider-inputs column">
                                <label>Nombre</label>
                                <div className="white-space-8"></div>
                                <input type="text" name="name" id="provider-name" required />
                                <div className="white-space-8"></div>
                                <label>Teléfono</label>
                                <div className="white-space-8"></div>
                                <input type="tel" name="phone" id="provider-phone" required maxLength={12} pattern="[0-9.]+" title="telefono 10 digitos"/>
                                <div className="white-space-8"></div>
                                <label>Correo</label>
                                <div className="white-space-8"></div>
                                <input type="email" name="email" id="provider-email" required/>
                                {/*{edit ?
                                <React.Fragment>
                                    <div className="white-space-8"></div>
                                    <div className="provider-status align-center">
                                        <label>Activo</label>
                                        <input className="input" id="status" name="status" type="checkbox" checked={this.state.estado === 1 ? true : false}
                                            onChange={(event) => {
                                                const status = event.target.checked ? 1 : 0;
                                                this.setState({ estado: status });
                                            }} />
                                    </div>
                                </React.Fragment>
                                : null}*/}
                            </div>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    {/*<div className="white-space-24"></div>
                    <div className="row justify-center">
                        {this.state.message}
                    </div>
                    <div className="white-space-24"></div>*/}
                    <div className="modal-controlls">
                        <button className="btn btn-square btn-red color-white weight-semi" type="button" id="close-modal-delete" onClick={this.close.bind(this)}>
                            <i className="fas fa-times color-white"></i> CANCELAR
						</button>
                        {this.state.loading?
                        <button className="btn btn-square btn-blue weight-semi" type="button" id="btn-deleteChat">
                            <i className="fas fa-spinner fa-spin"></i>
                        </button>
                        :
                        <button className="btn btn-square btn-blue weight-semi" type="sumbit" id="btn-deleteChat" >
                            <i className="fas fa-check"></i> ACEPTAR
						</button>
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default AddProveedor;