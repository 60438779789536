import React, { Component } from 'react';

// Web Components
import Aside from '../components/aside/aside';
import ClientsTable from '../components/clients/clients-table';
import Paginator from '../components/paginator/paginator';

class Clientes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
    }

    setpage(page) {
        this.setState({ page: page })
    }

    render() {
        return(
            <div className="workspace">
                <Aside />
                <div className="control-content justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <div className="align-center">
                            <div>
                                <h3 className="color-black">
                                    TODOS LOS CLIENTES
                                </h3>
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                        <div className="white-space-16"></div>
                        <ClientsTable page={this.state.page} />
                        <div className="white-space-32"></div>
                        <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.props.clients.length / 10)} /> 
                        <div className="white-space-32"></div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Clientes;
