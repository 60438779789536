import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Request from '../core/httpClient';

// Web Components
import Aside from "../components/aside/aside";
import CategoriesTable from "../components/categories/category-table";
import Paginator from "../components/paginator/paginator";
import Header from "../components/header/header";

const req = new Request();

class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      categories: []
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({message: '', loading: true});
    const res = await req.post('/admin/categories/get');
    this.setState({message: res.message});
    if(res.categories) {
      this.setState({categories: res.categories});
    }
    this.setState({loading: false});
  }

  setpage(page) {
    this.setState({ page: page });
  }

  render() {
    return (
      <div className="workspace">
        <div className="column">
          <Header/>
          <div>
            <Aside />
            <div className="control-content justify-center">
              <div className="container column">
                <div className="white-space-32" />
                <div className="align-center">
                  <div>
                    <h3 className="color-black">CATEGORIAS</h3>
                  </div>
                  <div className="white-space-16" />
                </div>
                <div className="white-space-16" />
                <div className="row">
                  <div className="left justify-start">
                    <button
                      className="btn btn-green" onClick={this.load.bind(this)} >
                      <i className="fas fa-sync" />
                      &nbsp; ACTUALIZAR
                </button>
                  </div>
                  <div className="right justify-end">
                    <Link to="categorias/agregar" className="btn btn-green" id="open-modal-add-user">
                      <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR CATEGORÍA
                </Link>
                  </div>
                </div>
                <div className="white-space-16" />
                <CategoriesTable page={this.state.page} categories={this.state.categories} />
                <div className="white-space-32" />
                {this.state.categories.length > 10 ?
                  <Paginator
                    setpage={this.setpage.bind(this)}
                    items={Math.ceil(this.state.categories.length / 10)}
                  /> : null}
                <div className="white-space-32" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categorias;

