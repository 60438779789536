import React, { Component } from 'react';

// Web Componentes
import TCRow from './tc-row';

class TCTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numitems: 10
        };
    }

    render() {
        let items = [];
        if (this.props.tc) {
            items = this.props.tc.slice((this.props.page - 1) * 10, this.props.page * 10);
        }

        const style = {tableLayout: 'fixed'};

        return(
            <div className="users-table">
                <div className="table-responsive">
                    <table style={style}>
                        <thead>
                            <tr>
                                <th> USD </th>                                
                                <th> FECHA </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.loading ?
                                    (
                                        <tr>
                                            <td colSpan="7">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                    ) : (null)
                            }
                            {
                                items.map((tc, index) => (
                                    <TCRow key = { index } tc = { tc } />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );

    }

}

export default TCTable;
