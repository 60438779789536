import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AgenciasTable from '../components/agencias/agencias_table';
import Aside from "../components/aside/aside";
import Header from "../components/header/header";
import AddAgencias from '../components/modals/add-agencias';
import Request from '../core/httpClient';

const req = new Request();

class Agencias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencias: [],
            tipo: '',
            agencia: []
        };
    }

    modalAddAgencia(id, data) {
        this.setState({ tipo: id, agencia: data });
        const modal = document.getElementById("modal-add-agencia");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    componentDidMount() {
        this.getAgencias();
    }

    async getAgencias() {
        const res = await req.post('/admin/agencias/get');
        if (res.agencias) {
            this.setState({ agencias: res.agencias });
        }
    }

    render() {
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">AGENCIAS</h3>
                                    </div>
                                    <div className="white-space-16" />
                                </div>
                                <div className="white-space-16" />
                                <div className="row">
                                    <div className="left justify-start">
                                        <button
                                            className="btn btn-green" onClick={this.getAgencias.bind(this)}>
                                            <i className="fas fa-sync" />
                                            &nbsp; ACTUALIZAR
                                        </button>
                                    </div>
                                    <div className="right justify-end">
                                        <button className="btn btn-green" onClick={this.modalAddAgencia.bind(this, 1, {})}>
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR AGENCIA
                                        </button>
                                    </div>
                                </div>
                                <div className="white-space-16" />
                                <AgenciasTable agencias={this.state.agencias} modalAddAgencia={this.modalAddAgencia.bind(this)} getAgencias={this.getAgencias.bind(this)} />
                            </div>
                        </div>
                        <AddAgencias tipo={this.state.tipo} agencia={this.state.agencia} getAgencias={this.getAgencias.bind(this)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Agencias;