import React, { Component } from 'react';
import Imagen1 from './../../img/playa.jpg';
import { Link } from 'react-router-dom';

class BannerRow extends Component {
    render() {
        let product = [], nombre = '', descripcion = '', image = '';
        if (this.props.product) {
            product = this.props.product;

            if (product.nombre) {
                nombre = JSON.parse(product.nombre);
                nombre = nombre['es'];
            }

            if (product.descripcion) {
                descripcion = JSON.parse(product.descripcion);
                descripcion = descripcion['es'];
            }

            if (product.imagen) {
                image = JSON.parse(product.imagen);
                if (image['es'] !== '') {
                    image = image['es'];
                } else if (image['en'] !== '') {
                    image = image['en'];
                } else if (image['pt'] !== '') {
                    image = image['pt'];
                }
            }   
        }

        return(
            <tr className="table-row text-center">
                <td>
                    {product.idBProducto}
                </td>
                <td>
                    <div className="justify-center">
                        <img width="80px" height="80px" src={image} alt="" />
                    </div>
                </td>
                <td>
                    {nombre}
                </td>
                <td>
                    {descripcion}
                </td>
                <td>
                    <input type="checkbox" name="banner-status" id="banner-status" checked={product.status === 1 ? true : false} disabled />
                </td>
                <td>
                    <Link to={"productos/banner/modificar/" + product.idBProducto}>
                        <i class="fas fa-edit"></i>
                    </Link>
                </td>
            </tr>
        )
    }
}

export default BannerRow;