import React, { Component } from "react";
import Request from '../../core/httpClient';

// Web Components
import ProveedorRow from "./proveedor-row";
import CostosRow from "./costos-row";

const req = new Request();

class CostosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numitems: 10,
            id_delete: '',
            id_producto: ''
        };
    }

    delete(idProducto) {
        console.log(idProducto);
        this.setState({ id_producto: idProducto });
    }

    async deleteTour() {
        console.log(this.props.tours)
        let tours = this.props.tours.filter(tour => parseInt(tour.id_producto) !== parseInt(this.state.id_producto));
        if (tours) {
            console.log(tours)
            const res = await req.post('/admin/providers/save/tours', { tours, id_proveedor: this.props.id_proveedor });
            if (res.updated) {
                const modal = document.getElementById('modal_delete_costo');
                if (modal) {
                    modal.classList.add("hidden");
                }
                this.props.load();
            }
        }
    }

    render() {
        return (
            <div className="clients-table">
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th className="th-name">ID</th>
                                <th className="th-name">TOUR</th>
                                <th className="th-name">PRECIO ADULTO</th>
                                <th className="th-name">PRECIO NIÑO</th>
                                <th className="th-name">ELIMINAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.loading ? (
                                <tr>
                                    <td colSpan={4}>
                                        <i className="fas fa-spinner fa-spin" />
                                    </td>
                                </tr>
                            ) : (
                                this.props.tours.map((tour, index) =>
                                    <CostosRow 
                                        key={index}
                                        {...tour}
                                        tours={this.props.tours}
                                        load={this.props.load.bind(this)}
                                        delete={this.delete.bind(this)}
                                        modalAddProveedor={this.props.modalAddProveedor.bind(this)}
                                        id_proveedor={this.props.id_proveedor} />
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex modal hidden" id="modal_delete_costo">
                    <div className="container modal-content column">
                        <div className="modal-header justify-center">
                            <h2 className="color-white">
                                ELIMINAR
                            </h2>
                        </div>
                        <div className="modal-body column">
                            <div className="white-space-32"></div>
                            <div className="justify-center">
                                {this.state.delete_message}
                            </div>
                            <div className="white-space-32"></div>
                            <div className="row justify-center">
                                {this.state.delete_loading ?
                                    <div className="btn btn-aqua">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    :
                                    <button className="btn btn-aqua" onClick={this.deleteTour.bind(this)}>
                                        ACEPTAR
                                    </button>
                                }
                                &nbsp;
                                    <button className="btn btn-red" onClick={() => {
                                    const modal = document.getElementById('modal_delete_costo');
                                    if (modal) {
                                        modal.classList.add("hidden");
                                    }
                                }}>
                                    CANCELAR
                                    </button>
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CostosTable;