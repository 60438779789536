import React from "react";
import { Link } from "react-router-dom";
import { FormattedNumber } from "react-intl";

const ProductRow = function(product) {
  let titulo = '';
  if(product.titulo) {
    titulo = JSON.parse(product.titulo);
    titulo = titulo['es'];
  }
  return (
    <tr className="table-row">
      <td className="table-image">
        <img src={product.imagen} alt={product.id_producto} id="product-image" width="80px" height="80px"/>
      </td>
      <td className="table-product-name">{titulo}</td>
      <td>
        <input type="checkbox" checked={product.id_status} disabled />
      </td>
      <td>
        <input type="checkbox" checked={product.relevante > 0? true:false} disabled />
      </td>
      <td className="table-total">
        <FormattedNumber
          currency="USD"
          style="currency"
          value={product.precio_adulto}
        />
      </td>
      <td className="table-total">
        <FormattedNumber
          currency="USD"
          style="currency"
          value={product.precio_menor}
        />
      </td>
      <td className="edit">
        <Link to={"productos/modificar/" + product.id_producto} className="btn">
          <i className="fas fa-pencil-alt" />
        </Link>
      </td>
    </tr>
  );
};

export default ProductRow;
