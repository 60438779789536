import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import dayjs from "dayjs";

class StatusTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estado: this.props.order.estado
    };
  }
  selectChange(event) {
    this.setState({ estado: event.target.value });
    this.props.estadoSelect(event.target.value);
  }
  render() {
    let fecha = this.props.order.fecha? this.props.order.fecha.replace("Z", "") : dayjs();
    const select_estado = (
      <select
        name="status"
        id="status"
        className="input input-select"
        value={this.state.estado}
        onChange={this.selectChange.bind(this)}
      >
        <option value="SOLICITADO">SOLICITADO</option>
        <option value="PAGADO">PAGADO</option>
        <option value="PENDIENTE DE ENVIO">PENDIENTE DE ENVIO</option>
        <option value="ENVIADO">ENVIADO</option>
        <option value="COMPLETADO">COMPLETADO</option>
        <option value="CANCELADO">CANCELADO</option>
      </select>
    );
    return (
      <div className="justify-center">
        <div className="container column">
          <div className="table-responsive table-total-summary">
            <table>
              <thead>
                <tr>
                  <th className="th-folio"> FOLIO </th>
                  <th className="th-status"> ESTATUS </th>
                  <th className="th-date"> FECHA </th>
                  <th className="th-total"> TOTAL </th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-row">
                  <td> {this.props.order.folio} </td>
                  <td>{select_estado}</td>
                  <td>
                    {dayjs(fecha).format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td className="table-total total-check">
                    <FormattedNumber
                      currency="EUR"
                      style="currency"
                      value={this.props.order.importe}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default StatusTable;
