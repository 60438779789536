import React, { Component } from "react";
import Request from '../core/httpClient';
// Web Components
import Aside from "../components/aside/aside";
import ProveedorTable from "../components/proveedores/proveedor-table";
import Paginator from "../components/paginator/paginator";
import AddProveedorModal from "../components/modals/add-proveedor";
import Header from "../components/header/header";
const req = new Request();

class Proveedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            proveedores: [],
            proveedor: {
                proveedor: '',
                telefono: '',
                email: ''
            }
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({message: '', loading: true});
        const res = await req.post('/admin/providers/get');
        this.setState({message: res.message});
        if(res.providers) {
          this.setState({proveedores: res.providers});
        }
        this.setState({loading: false});
    }

    modalAddProveedor(id, data) {
        this.setState({ tipo: id, proveedor: data });
        const modal = document.getElementById("modal-add-proveedor");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    setpage(page) {
        this.setState({ page: page });
    }

    render() {
        return(
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">PROVEEDORES</h3>
                                    </div>
                                    <div className="white-space-16" />
                                </div>
                                <div className="white-space-16" />
                                <div className="row">
                                    <div className="left justify-start">
                                        <button
                                            className="btn btn-green" onClick={this.load.bind(this)} >
                                            <i className="fas fa-sync" />
                                            &nbsp; ACTUALIZAR
                                </button>
                                    </div>
                                    <div className="right justify-end">
                                        <button className="btn btn-green" onClick={this.modalAddProveedor.bind(this, 1, { proveedor: '', telefono: '', email: '' })}>
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR PROVEEDOR
                                </button>
                                    </div>
                                </div>
                                <div className="white-space-16" />
                                <ProveedorTable loading={this.state.loading} page={this.state.page} proveedores={this.state.proveedores} modalAddProveedor={this.modalAddProveedor.bind(this)} />
                                <div className="white-space-32" />
                                {this.state.proveedores.length > 10 ? 
                                <React.Fragment>
                                    <Paginator
                                        setpage={this.setpage.bind(this)}
                                        items={Math.ceil(this.state.proveedores.length / 10)}
                                    />
                                    <div className="white-space-32" />
                                </React.Fragment> : null}
                            </div>
                        </div>
                        <AddProveedorModal provider={this.state.proveedor} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Proveedores;