import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Request from '../core/httpClient';

// Web Components
import Aside from "../components/aside/aside";
import HotelTable from "../components/hoteles/hotel-table";
import Paginator from "../components/paginator/paginator";
import AddHotelModal from "../components/modals/add-hotel";
import Header from "../components/header/header";

const req = new Request();

class Hoteles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            hoteles: [],
            idHotel: '',
            tipo: '',
            hotel: []
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ message: '', loading: true });
        const res = await req.post('/admin/hoteles/get');
        this.setState({ message: res.message });
        if (res.hoteles) {
            this.setState({ hoteles: res.hoteles });
        }
        this.setState({ loading: false });
    }

    modalAddHotel(id, data) {
        this.setState({ tipo: id, hotel: data });
        const modal = document.getElementById("modal-add-hotel");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    setpage(page) {
        this.setState({ page: page });
    }

    render() {
        return (
            <div className="workspace">
                <div className="column">
                    <Header />
                    <div>
                        <Aside />
                        <div className="control-content justify-center">
                            <div className="container column">
                                <div className="white-space-32" />
                                <div className="align-center">
                                    <div>
                                        <h3 className="color-black">HOTELES</h3>
                                    </div>
                                    <div className="white-space-16" />
                                </div>
                                <div className="white-space-16" />
                                <div className="row">
                                    <div className="left justify-start">
                                        <button
                                            className="btn btn-green" onClick={this.load.bind(this)} >
                                            <i className="fas fa-sync" />
                                            &nbsp; ACTUALIZAR
                                        </button>
                                    </div>
                                    <div className="right justify-end">
                                        <button className="btn btn-green" onClick={this.modalAddHotel.bind(this, 1, {})}>
                                            <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR HOTEL
                                        </button>
                                    </div>
                                </div>
                                <div className="white-space-16" />
                                <HotelTable page={this.state.page} hoteles={this.state.hoteles} modalAddHotel={this.modalAddHotel.bind(this)} />
                                <div className="white-space-32" />
                                {this.state.hoteles.length > 10 ?
                                    <Paginator
                                        setpage={this.setpage.bind(this)}
                                        items={Math.ceil(this.state.hoteles.length / 10)}
                                    /> : null}
                                <div className="white-space-32" />
                            </div>
                        </div>
                        <AddHotelModal tipo={this.state.tipo} hotel={this.state.hotel} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Hoteles;
