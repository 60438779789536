import React, { Component } from "react";

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    submit(event) {
        event.preventDefault();
        const data = {
            folio: event.target.folio.value,
            estado: event.target.estado.value
        }

        this.props.filter(data);
    }
  render() {
    return (
      <div className="container-search-order justify-start">
        <form className="filters" onSubmit={this.submit.bind(this)}>
          <div className="column">  
            <h5 className="color-black">FILTRAR PEDIDOS</h5>
            <div className="white-space-8" />
            <input
            name="folio"
              type="text"
              className="input input-text"
              placeholder="N° de  Folio"
            />
          </div>
          &nbsp;
          <div className="column">
            <h5 className="auto color-black">ESTADO: &nbsp;</h5>
            <div className="white-space-8" />
            <select name="filter-select" name="estado" className="input input-select">
              <option value="">TODOS</option>
              <option value="RESERVADO">RESERVADO</option>
              <option value="PAGADO">PAGADO</option>
              <option value="CANCELADO">CANCELADO</option>
              <option value="FINALIZADO">FINALIZADO</option>
            </select>
          </div>
          <div className="button-filter justify-start align-end">
            <button className="btn btn-yellow" type="submit">
              <i className="fas fa-list"></i>&nbsp; FILTRAR
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Filters;
