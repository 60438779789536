import React, { Component } from 'react';
import { Consumer } from '../../context';
import Logo from '../../img/Imagotipo.png';

//Web Components

// Images
import Images from '../../img/index';

class Header extends Component {

    renderAuth() {
        return (
            <div className="user-options justify-end">
                <div className="user align-center auto">
                    <p>
                        Hola, <b> {this.props.context.user.nombre} </b>
                    </p>
                </div>
                <div className="signout auto align-center" onClick={()=> this.props.context.logout(this)}>
                    <p className="link">
                        <b>
                            <i className="fas fa-sign-out-alt"></i>&nbsp; SALIR
                        </b>
                    </p>
                </div>
            </div>
        )
    }
    renderLogin() {
        return (
            <div className="user-options justify-end">
                <div className="signout auto align-center" onClick={()=>window.location = '/login'}>
                    <p className="link">
                        <b>
                            <i className="fas fa-sign-out-alt"></i>&nbsp; Iniciar Sesión
                        </b>
                    </p>
                </div>
            </div>
        )
    }
    render() {
        return(
            <header className="header flex justify-center">
                <div className="container">
                    <a className="responsive-img logo auto align-center">
                        <img src={Logo} alt="Vamos Cancún Logo" title="Vamos Cancún Logo" className="logo cover-img"/>
                    </a>
                    { this.props.context.auth ? this.renderAuth() : null }
                </div>                
            </header>
        );
    }

}

export default Consumer(Header);
